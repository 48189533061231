<template>
    <div class="to_be_update_wechat">
        <ul class="row">
            <li class="cell" v-for="item in toBeUpdateList" :key="item.id" @click="toWeChatDetail(item)">
                <div class="left">
                    <template v-if="item.wechatType === 21">
                        <img src="../assets/images/fav.png" alt="">
                        AskBot客户端
                    </template>
                    <template v-else>
                        <img :src="getUrl(item.wechatType)" alt="">
                        {{ item.agentName }}
                    </template>
                </div>
                <div class="right" v-if="!disabled">
                    <i class="iconfont el-icon-arrow-right"></i>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    data() {
        return {
            // toBeUpdateList: []
        }
    },
    props:["list", "disabled"],
    computed: {
        toBeUpdateList () {
            if(this.list !== undefined) {
                return this.list
            } else {
                return this.$store.state.toBeUpdateList
            }
        }
    },
    mounted() {

    },
    methods: {
        getUrl(wechatType) {
            if (wechatType == 0) {
                return require(`../assets/images/qiyechat.png`)
            } else if (wechatType == 1) {
                return require(`../assets/images/Wechat_128px_1230528.png`)
            } else if (wechatType == 2) {
                return require(`../assets/images/xiaochengxu.jpeg`)
            } else if (wechatType == 3 || wechatType == 6 || wechatType == 7 || wechatType == 9) {
                return require(`../assets/images/dingding.jpeg`)
            } else if (wechatType == 4) {
                return require(`../assets/images/webchat.png`)
            } else if (wechatType == 10) {
                return require(`../assets/images/feishu.webp`)
            } else if (wechatType == 13) {
                return "iconfont guoran-yun"
            } else if (wechatType == 20) {
                return "iconfont guoran-guanlixitong1"
            } else if (wechatType == 22) {
                return require(`../assets/images/zhiyuan.png`)
            } else if (wechatType === 'askBot') {
                return require('../assets/images/fav.png')
            }
        },
        wechatTypeName(type) {
            switch (type) {
                case 0:
                    return 'wechat'
                case 1:
                    return 'wechatAccount'
                case 2:
                    return 'miniProgram'
                case 3:
                    return 'dingding'
                case 4:
                    return 'website'
                case 6:
                    return 'dingdingGroup'
                case 7:
                    return 'dingdingGroup'
                case 9:
                    return 'dingSingleChatBot'
                case 10:
                    return 'feishu'
                case 13:
                    return 'yunzhijia'
                case 20:
                    return 'IM'
                case 22:
                    return 'zhiyuan'
                case "askBot":
                    return 'askBot'
                case 21:
                    return 'askBot'    
            }
        },
        toWeChatDetail(item) {
            if(this.disabled) {
                return
            }
            let type = this.wechatTypeName(item.wechatType)
            this.$router.push({
                path: "/accessDetails",
                query: {
                    type,
                    bindCode: item.bindCode,
                    id: item.id,
                    agentId: item.agentId
                }
            })
            sessionStorage.setItem('previousActive', 'ability')
        },
       
    }
}
</script>

<style lang="less" scoped>
.to_be_update_wechat {
    width: 100%;
    height: auto;

    .row {
        width: 100%;

        li {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            cursor: pointer;

            .left {
                color: #000000;
                display: flex;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }
            }

            .right {
                i {
                    color: #366aff;
                    font-weight: 600;
                }
            }
        }

    }
}
</style>