import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// 准备state存储数据
let state = {
    toBeUpdateList: []
    //状态对象
}

// mutations操作数据state
let mutations = {
    CHANGE_TO_BE_UPDATE_LIST(state, data) {
        state.toBeUpdateList = data
    }
}


const store = new Vuex.Store({
    state,
    mutations
});
export default store;