<template>
    <div class="content">
        <div :class="['nav',uid == 20281 || uid == 117431 ? 'no-bgc' : '']">
            <div class="logo">
                <img v-if="uid == 20281 || uid == 117431" src="../../assets/images/jdLogo.png" alt="" srcset="">
                <svg
                    v-else
                    t="1648199555802"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="77606"
                    width="37"
                    height="37"
                >
                    <path
                        d="M650.752 18.944H373.248c-174.08 0-315.392 141.312-315.392 315.392v276.992c0 174.08 141.312 315.392 315.392 315.392h240.128l146.432 81.92c23.552 13.312 52.224-3.584 52.736-30.72v-96.256c91.648-55.296 153.6-155.648 153.6-270.336V334.336c0-174.592-141.312-315.392-315.392-315.392zM512 754.176c-137.728 0-249.856-103.936-256.512-235.008 0-1.024-0.512-2.048-0.512-3.072V225.28c0-14.848 12.288-27.136 27.136-27.136h130.56c14.848 0 27.136 12.288 27.136 27.136v44.544c23.04-6.656 47.104-10.24 72.192-10.24 142.336 0 257.536 110.592 257.536 246.784 0 137.216-115.2 247.808-257.536 247.808z"
                        fill="#ffffff"
                        p-id="77607"
                    ></path>
                    <path
                        d="M627.2 409.6h-230.4c-45.568 0-82.432 36.864-82.432 82.432 0 45.568 36.864 82.432 82.432 82.432h229.888c45.568 0 82.432-36.864 82.432-82.432 0-45.568-36.864-82.432-81.92-82.432z m-223.744 128.512c-25.6 0-46.08-20.48-46.08-46.08s20.48-46.08 46.08-46.08 46.08 20.48 46.08 46.08-20.48 46.08-46.08 46.08z m217.088 0c-25.6 0-46.08-20.48-46.08-46.08s20.48-46.08 46.08-46.08 46.08 20.48 46.08 46.08-20.992 46.08-46.08 46.08z"
                        fill="#ffffff"
                        p-id="77608"
                    ></path>
                </svg>
            </div>
            <el-menu
                default-active="1"
                class="el-menu-vertical-ui-u"
                background-color="#1D2E54"
                text-color="#fff"
                active-text-color="#fff"
                :router="true"
                :collapse="true"
            >
                <el-menu-item index="/index" v-if="isShowIndex"
                    ><i
                        class="iconfont guoran-shouye1"
                        :class="isHomeActive ? 'active-item' : ''"
                    ></i
                    ><span slot="title">{{$t('common.homepage')}}</span></el-menu-item
                >
                <el-menu-item
                    :index="activeIndex"
                    v-if="((roles !== '人工客服' && roleVisable) || mniuOnlyShowDuty == 1) && uid != 117431"
                    ><i
                        class="iconfont guoran-a-14-03"
                        :class="isCompanyManageActive ? 'active-item' : ''"
                    ></i
                    ><span slot="title">{{$t('common.enterpriseEdition')}}</span></el-menu-item
                >
                <el-menu-item index="/channel"
                              v-if="hasChannel"
                ><i
                    class="iconfont guoran-tongyichicun-qudaojieru"
                    :class="isChannel ? 'active-item' : ''"
                ></i
                ><span slot="title">{{$t('common.channelManage')}}</span></el-menu-item
                >
                <el-menu-item index="/_personal"
                    ><i
                        class="iconfont guoran-a-14-14"
                        :class="isPersonalActive ? 'active-item' : ''"
                    ></i
                    ><span slot="title">{{$t('common.personal')}}</span></el-menu-item
                >
                <!-- <el-menu-item index="3"><i class="el-icon-setting"></i></el-menu-item> -->
            </el-menu>
            <div class="user-info">
                <svg
                    @click="showSwitch = true"
                    v-if="
                        !(
                            activeServiceObj.OPEN == -1 &&
                            activeServiceObj.WORKORDER == -1 &&
                            activeServiceObj.IM == -1 &&
                            activeServiceObj.KNOWLEDGE == -1 &&
                            activeServiceObj.QUESTION == -1
                        )
                    "
                    t="1648203029511"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="211692"
                    width="24"
                    height="24"
                >
                    <path
                        d="M969.216 228.352c18.944 0 34.304 15.36 34.304 34.304s-14.848 33.792-33.792 34.304h-99.84c84.992 69.632 125.952 161.28 120.832 262.144-5.632 105.984-62.976 213.504-153.6 294.912-14.336 12.8-35.84 11.264-48.64-2.56-12.8-14.336-11.264-35.84 2.56-48.64 77.824-69.632 125.952-160.256 130.56-246.784 4.608-82.944-30.72-157.696-107.52-214.528v110.08c0 18.944-14.848 33.792-33.792 34.304h-1.024c-18.944 0-33.792-14.848-34.304-33.792V263.168c0-18.944 14.848-33.792 33.792-34.304h1.024l189.44-0.512zM275.456 190.976c9.728 15.872 5.12 36.864-10.752 47.104l-0.512 0.512C168.448 296.96 124.416 378.88 129.536 471.04c3.584 69.12 34.816 140.8 87.04 202.24v-113.664c0-18.944 14.848-33.792 33.792-34.304h1.024c18.944 0 33.792 14.848 34.304 33.792v189.952c0 18.944-14.848 33.792-33.792 34.304H61.952c-18.944 0-34.304-15.36-34.304-34.304s14.848-33.792 33.792-34.304h99.328c-59.392-71.68-95.232-156.16-99.84-239.104-6.144-117.76 50.688-223.232 167.424-295.424 15.872-10.752 37.376-5.632 47.104 10.752z"
                        fill="#458FFF"
                        p-id="211693"
                    ></path>
                    <path
                        d="M549.376 17.408c52.224 0 94.72 42.496 94.72 94.72v62.976c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72V112.128c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                        fill="#FF9555"
                        p-id="211694"
                    ></path>
                    <path
                        d="M549.376 742.912c52.224 0 94.72 42.496 94.72 94.72v61.44c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72v-61.44c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                        fill="#00C2BB"
                        p-id="211695"
                    ></path>
                </svg>
                <transition name="fade">
                    <div v-if="showSwitch" class="switch-box">
                        <div class="tr-w">
                            <svg
                                t="1653379591086"
                                class="icon"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="2979"
                                width="16"
                                height="16"
                            >
                                <path
                                    d="M325.456896 862.27968"
                                    p-id="2980"
                                    fill="#ffffff"
                                ></path>
                                <path
                                    d="M882.05824 862.27968"
                                    p-id="2981"
                                    fill="#ffffff"
                                ></path>
                                <path
                                    d="M236.027904 877.161472"
                                    p-id="2982"
                                    fill="#ffffff"
                                ></path>
                                <path
                                    d="M817.898496 962.556928 817.898496 63.654912 207.63648 513.099776Z"
                                    p-id="2983"
                                    fill="#ffffff"
                                ></path>
                                <path
                                    d="M960.132096 877.161472"
                                    p-id="2984"
                                    fill="#ffffff"
                                ></path>
                            </svg>
                        </div>
                        <span
                            @click="showSwitch = false"
                            class="switch-box-close"
                        >
                            <i class="arsenal_icon arsenalcuo1"></i>
                        </span>
                        <span class="switch-box-title">{{$t('common.switchingSystems')}}</span>
                        <div class="switch-box-content">
                            <ActiveService
                                :activeServiceObj="activeServiceObj"
                                :userInfo="userInfo"
                            />
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import { redirect2LoginPage } from "../../const/redirectUri";
import { operable } from "../companyManage/js/operable";
import ActiveService from "./../index/components/ActiveService.vue";

export default {
    components: { ActiveService },
    data() {
        return {
            showSwitch: false,
            currentPath: "",
            isHomeActive: false,
            isCompanyManageActive: false,
            isChannel:false,
            isCertified: true,
            isPersonalActive: false,
            roles: "",
            userInfo: {},
            userCompany: {
                status: 0,
                companyName: "",
                modules: [],
            },
            entranceStatus: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            moduleShow: {
                OPEN: false,
                IM: false,
                WORKORDER: false,
            },
            companyId: null,
            rolePrivilegesList: [],
            activeServiceObj: {
                OPEN: -1,
                IM: -1,
                WORKORDER: -1,
                STATUS: -1,
                KNOWLEDGE:-1,
                ASKAI:-1,
                QUESTION: -1,
            },
            activeIndex: "",
            isShowIndex:true,
            _ask_uid: "",
            mniuOnlyShowDuty: 0,
             uid:null,
          hasChannel:false
        };
    },
    computed: {
        roleVisable: function () {
            return operable("PORTAL_MANAGEMENT");
        },
    },
    methods: {
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
        getUserCompany() {
            this.$http
                .get(this.requestUrl.companyManage.getUserCompanyModule)
                .then((res) => {
                    if (res.data && res.data.code === "0") {
                        if (res.data.data != null) {
                            if (this.$route.name == "Index") {
                                this.$emit("setHasCompanyName", res.data.data.name);
                            }
                            this.userCompany = res.data.data;
                            this.auditStatus = res.data.data.status;
                            this.companyId = this.userCompany.id;
                            if (this.userCompany.certification != null) {
                                this.certification =
                                    this.userCompany.certification;
                            }
                            let modules = this.userCompany.modules;
                            this.activeServiceObj.STATUS =
                                this.userCompany.status;
                            if (modules != null && modules.length > 0) {
                                modules.forEach((m) => {
                                    if (
                                        m.sysModule.code === "OPEN" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_OPEN"
                                        ) !== -1
                                    ) {
                                        m.styleStr = "";
                                        this.activeServiceObj.OPEN =
                                            m.sysModule.portalUrl;
                                    } else if (
                                        m.sysModule.code === "IM" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_IM"
                                        ) !== -1
                                    ) {
                                        this.activeServiceObj.IM =
                                            m.sysModule.portalUrl;
                                    } else if (
                                        m.sysModule.code === "WORKORDER" &&
                                        this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_WORKORDER"
                                        ) !== -1
                                    ) {
                                        this.activeServiceObj.WORKORDER =
                                            m.sysModule.portalUrl;
                                    } else if (
                                        m.sysModule.code === "KNOWLEDGE" && this.rolePrivilegesList.indexOf(
                                            "PORTAL_ENTRANCE_KNOWLEDGE"
                                        ) !== -1
                                    ) {
                                        this.activeServiceObj.KNOWLEDGE = m.sysModule.portalUrl;
                                    } else if (m.sysModule.code === "ASKAI"){
                                        this.activeServiceObj.ASKAI = m.sysModule.portalUrl;
                                    } else if(m.sysModule.name === "Ask问卷系统") {
                                        this.activeServiceObj.QUESTION = m.sysModule.portalUrl
                                        m.checked = true;
                                    }
                                });
                            }
                            this.userCompany.modules = [...modules];
                        }
                    }
                })
                .catch((res) => {
                    // location.hash = "/auth";
                });
        },
        handleCommand(command) {
            if (command == "a") {
                this.$router.push({ path: "/_personal" });
            } else if (command === "logout") {
                localStorage.setItem("cancel_bind_phone", 0);
                this.$http.delete("/api/sso/auth/sign-out").then((res) => {
                    if (res.status === 200) {
                        redirect2LoginPage();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
        getCurrentUrl() {
            this.currentPath = this.$route.path;
            if (this.currentPath == "/index" || this.currentPath == "/") {
                this.isCompanyManageActive = false;
                this.isHomeActive = true;
                this.isPersonalActive = false;
                this.isChannel = false;
            } else if (
                this.currentPath == "/_accountManage" ||
                this.currentPath == "/_companyInfo" ||
                this.currentPath == "/_staffManage" ||
                this.currentPath == "/_roleManage" ||
                this.currentPath == "/_addRole" ||
                this.currentPath == "/_dataRoleManage" ||
                this.currentPath == "/_wxAccount" ||
                this.currentPath == "/_upDownManage" ||
                this.currentPath == "/_workTime" ||
                this.currentPath == "/_workDuty" ||
                this.currentPath == "/_inviteEnterEnterprise" || 
                this.currentPath == "/_capacity" || 
                this.currentPath == "/_flow" || 
                this.currentPath == "/accountTemplate" || 
                this.currentPath == "/accountFieldType" ||
                this.currentPath == "/setLanguage"
            ) {
                this.isCompanyManageActive = true;
                this.isHomeActive = false;
                this.isPersonalActive = false;
                this.isChannel = false;
            } else if (this.currentPath == "/_personal") {
                this.isPersonalActive = true;
                this.isCompanyManageActive = false;
                this.isHomeActive = false;
                this.isChannel = false;
            } else if (this.currentPath === '/channel' ||
                this.currentPath === '/accessDetails' ||
                this.currentPath === '/intentWelcomeMessage' ||
                this.currentPath === '/accessConfiguration' ||
                this.currentPath === '/knowledgeWelcomeMessage' ||
                this.currentPath === '/intelligentAgentConfig'){
                this.isPersonalActive = false;
                this.isCompanyManageActive = false;
                this.isHomeActive = false;
                this.isChannel = true;
            }
        },
        //获取用户的角色
        getRole() {
            this.$http.get("/api/account/current").then((res) => {
                if (res.data.code === "0") {
                    this.userInfo = res.data.data;
                    localStorage.setItem(
                        "userInfo",
                        JSON.stringify(this.userInfo)
                    );
                    if (this.$route.name == "Index") {
                        this.$emit("setIsShowTel", {
                            tel: res.data.data.tel,
                            oldUser: res.data.data.oldUser,
                            source: res.data.data.source,
                        });
                        // this.$emit("setHasCompanyName", res.data.data.realName);
                    }
                    if (this.userInfo.roles[0] !== null) {
                        this.roles = this.userInfo.roles[0];
                    }

                    // rolePrivileges json串 有一个关于上下游的选项选中了，并且source不等于5显示上下游和其他   boo = true && source !==5
                    //  source=5就只显示上下游
                    // let boo = false;
                    // JSON.parse(localStorage.getItem("rolePrivileges")).forEach(
                    //     (item) => {
                    //         if (
                    //             item.id.indexOf("PORTAL_MANAGEMENT_UPANDDOWN") >
                    //             -1
                    //         ) {
                    //             boo = true;
                    //             return;
                    //         }
                    //     }
                    // );
                    // let isShowOther =
                    //     JSON.parse(localStorage.getItem("userInfo")).source !==
                    //     5
                    //         ? true
                    //         : false;
                    // let isShowUpDownManage =
                    //     (boo &&
                    //         JSON.parse(localStorage.getItem("userInfo"))
                    //             .source !== 5) ||
                    //     JSON.parse(localStorage.getItem("userInfo")).source ===
                    //         5
                    //         ? true
                    //         : false;
                    // localStorage.setItem("isShowOther", isShowOther);
                    // localStorage.setItem(
                    //     "changeShowUpDownManage",
                    //     isShowUpDownManage
                    // );
                    //  this.isShowIndex = localStorage.getItem('isShowIndex') == 'true' ? true : false;
                    this.isShowIndex = localStorage.getItem('isShowIndex') == 'true' ? true : false;
                    this.activeIndex = this.isShowIndex
                        ? "/_accountManage"
                        : "/_upDownManage";

                    // mniu 白名单处理
                    this._ask_uid = Number(localStorage.getItem('_uid'));
                    if ([58003, 58013, 58048, 585].indexOf(this._ask_uid) != -1) {
                        this.activeIndex = "/_workDuty";
                    }
                   
                }
            });
        },
    },
    mounted() {
        this.rolePrivilegesList = this.getRolePrivileges();
        this.getCurrentUrl();
        let mainId = localStorage.getItem('_mainId');
        // 李宁白名单
        // 智能体入口白名单
        let mainIds = [
            '86dc09da570948d2b4841122d732f373',
            "fb348d095c0b4fd7bbd37826563dac7d", 
            "8b9bd566e3e64156ab764b19defc9d48",
            "0420b8da34c7450bbc038d824c35f8d0",
            "3786b17bf8204f80a6b7a35635c46648", // 夺汇主体 id
            "d17823a142dc46749b48452c1c678a24", // 郑哥的线上演示企业
            "ae130e3d9ca542bc95ea86deddf68da7", // 永旺主体
            "6d006f341ed743089be5eb1ad69f5cdf", // 今麦郎
            "5ecf2fcd704541149201ab9c1c31162d", // 安正主体
            "f277d946a181410b8eecbd22c448180e", // 正新主体
            "e93c327548c94b15a4b2b71bc613ef38", // 银轮主体
            "dd3f376f901548b8a5e3530434be7c9e", // 张昱线上演示企业
            "262f1c19fc274adcab0080588b1d288b", // 古马众物
            "e18906a23a3240dd83ed8efd4929f1ef", // 北京果然智汇科技有限公司
        ]
        // if (process.env.VUE_APP_NODE_ENV !== 'production' || mainIds.indexOf(mainId) !== -1){
        //     this.hasChannel = true;
        // }
        // if(process.env.VUE_APP_SOURCE === 'lishi' && localStorage.getItem("_uid")) {
        //     this.hasChannel = true;
        // }
        
        // 智能体入口放开所有
        this.hasChannel = true;
        // this.getUserCompany();   

        // mniu 白名单处理
        this._ask_uid = Number(localStorage.getItem('_uid'));
        if ([58003, 58013, 58048, 585].indexOf(this._ask_uid) != -1) {
            this.mniuOnlyShowDuty = 1;
        }
        localStorage.setItem('_mniuOnlyShowDuty', this.mniuOnlyShowDuty);
    },
    created(){
        this.getUserCompany();
        this.uid = localStorage.getItem('_uid')
        this.getRole();

    }
};
</script>
<style lang="less">
.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 64px;
    .nav {
        width: 64px;
        background-color: #1d2e54;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .logo {
            flex: none;
            margin-bottom: 18px;
            width: 100%;
            height: 55px;
            line-height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #366aff;
        }

        .el-menu-vertical-ui-u {
            flex: auto;
            width: 64px;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            .el-tooltip {
                padding: 0 !important;
            }
            .el-menu-item {
                padding: 0 !important;
                width: 64px !important;
                height: 38px;
                line-height: 38px;
                margin-bottom: 20px;
                i {
                    color: white !important;
                    font-size: 20px !important;
                }
                i.active-item {
                    color: white;
                    font-size: 20px;
                    display: inline-block;
                    width: 38px !important;
                    height: 38px !important;
                    background-color: #366aff;
                    border-radius: 5px;
                }
            }
        }
        .user-info {
            flex: none;
            height: 40px;
            margin-bottom: 20px;
            cursor: pointer;

            .switch-box {
                z-index: 9;
                height: 200px;
                background: #ffffff;
                box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
                border-radius: 5px;
                position: absolute;
                left: 65px;
                bottom: 20px;
                .tr-w{
                    width: 16px;
                    position: absolute;
                    left: -10px;
                    bottom: 15px;
                }
                .switch-box-close {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    display: block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #ffffff;
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
                    border-radius: 50%;
                    cursor: pointer;
                    i {
                        display: inline-block;
                        margin: 0 auto;
                        font-size: 18px;
                        color: #616161;
                    }
                }
                .switch-box-title {
                    display: block;
                    text-align: center;
                    height: 17px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #000000;
                    line-height: 28px;
                    margin: 16px 0 25px;
                }
                .switch-box-content {
                }
            }
        }
        &.no-bgc{
            // background-color: transparent;
            // margin-bottom: 0;
            img{
                width: 48px;
                height: auto;
            }
            .logo{
                margin-bottom: 0;
                // padding-top: 18px;
            }
            .el-menu-vertical-ui-u{
                padding-top: 18px;
            }
        }
    }
}
.borderBottom {
    border-bottom: 2px solid #f5f8fb;
    line-height: 40px;
    i {
        color: #366AFF;
        font-size: 16px;
        padding-right: 10px;
    }
}
</style>
