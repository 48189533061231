let TBlist = []


let MnHRlist = ["969a139de1df48c6965ff9014777fbae", "606c68d8532d496eaf6b51fe7a33f7bf",'b52d52cd3533409fb4b75af79868575f']

export const isTB = () => {
    return TBlist.includes(localStorage.getItem("_mainId"))
}

export const isMnHr = () => {
    return MnHRlist.includes(localStorage.getItem("_mainId"))
}


export const mnFieldList = () => {
    return [
        {
            key: 'employeeCode',
            label: 'ID'
        },
        {
            key: 'type',
            label: ' 人员类型'
        },
        {
            key: 'genderDescr',
            label: '性别'
        },
        {
            key: 'email',
            label: '邮箱'
        },
        {
            key: 'officeLocationDescr',
            label: '工作地'
        },
        {
            key: 'positionDescr',
            label: '岗位描述'
        },
        {
            key: 'personalJobLevel',
            label: '个人职级'
        },
        {
            key: 'directSupName',
            label: '直接上级名称'
        },
        {
            key: 'directSuperiorId',
            label: '直接上级员工ID'
        },
        {
            key: 'departmentDescr',
            label: '部门长描述'
        },
        {
            key: 'socialInsuranceLocDescr',
            label: '社保缴纳地'
        },

        {
            key: 'staffAttributeDescr',
            label: '编制属性'
        },
        {
            key: 'hrStatusDescr',
            label: '员工状态'
        },
        {
            key: 'employeeCategoryDescr',
            label: '员工类别'
        },
        {
            key: 'entryDate',
            label: '入职时间'
        },
        {
            key: 'HRBPName',
            label: 'HRBPName'
        },
        {
            key: 'HRBP',
            label: 'HRBP'
        },
        {
            key: 'payrollEntityName',
            label: '发薪法人单位名称'
        },
        {
            key: 'laborContractUnitName',
            label: '劳动合同归属单位名称'
        },
        {
            key: 'insuranceEntityName',
            label: '保险缴纳法人单位名称'
        },
        {
            key: 'payrollCostCenterDescr',
            label: '发薪法人成本中心'
        },{
            key: 'insuranceCostCenterDescr',
            label: '保险缴纳法人成本中心'
        }
    ]
}