<template>
  <div class="permission">
    <div class="consultation-scope-top"
         v-for="(conditionsItem,conditionsIndex) in conditions"
         :key="conditionsIndex">
      <div
          class="consultation-scope-top-content"
          :class="conditionsItem.type === '' ? 'error-content' : ''"
      >
        <div class="select-cell-attr select-cell-inner">
          <el-popover
              :ref="'condition-popover'+ conditionsIndex"
              placement="bottom-start"
              width="300"
              v-model="conditionsItem.visible"
              popper-class="choose-conditions-poprver"
              :visible-arrow="false">
            <div
                :class="['choose-user-attrible one-column-ellipsis']"
                slot="reference">
              <span class="all-select" style="display: block;width: 220px;" v-if="conditionsItem.type === ''">{{ $t('common.selectPlaceholder') }}</span>
              <span class="all-select" style="display: block;width: 220px;" v-else-if="conditionsItem.type === 'ALL'">{{ $t("botLink.allPerson") }}</span>
              <span
                  v-else-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">{{
                  $t("botLink.staffOrDept")
                }}</span>
              <span
                  v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'label'">{{
                  $t("botLink.label")
                }}</span>
              <span
                  v-else-if="conditionsItem.type === 'CUSTOMIZE' && conditionsItem.key === 'personalJobLevel'">{{
                  $t("botLink.personalJobLevel")
                }}</span>
              <span v-else>{{ $t('botLink.' + conditionsItem.type) }}</span>
            </div>
            <el-tree
                style="max-height:300px;overflow-x:hidden;overflow-y:auto;"
                :data="userAttributeOptions"
                :props="{
                  children: 'children',
                  label: 'label',
                   id:'value'
                }"
                default-expand-all
                node-key="id"
                @node-click="(data) => onNodeClickCondition(data,conditionsItem,conditionsIndex)"
                ref="userAttributeTree">
              <span :class="['custom-tree-node',  data.value === 'STAFF'  ? 'disabled' : '']"
                    slot-scope="{node,data}">
                <span class="custom-tree-node-left">
                  <span class="data-label">{{ $t("botLink." + data.value) }}</span>
                </span>
              </span>
            </el-tree>
          </el-popover>
        </div>
        <template v-if="conditionsItem.type != ''">
          <div class="select-cell-attreq select-cell-inner" v-if="conditionsItem.type !== 'ALL' && conditionsItem.type !== 'SYSTEM_ACCOUNT'">
            <el-select
                size="small"
                v-model="conditionsItem.relation"
                :placeholder="$t('flowComponent.conditionConfigPopup.selectType')">
              <el-option
                  v-for="item in attrRelations"
                  :key="item.value"
                  :label="$t('common.' + item.value)"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <!-- 弹框选择组织架构 -->
          <div
              @click="openStaffPopup(conditionsItem,conditionsIndex)"
              class="select-cell-attrv select-cell-inner one-column-ellipsis"
              v-if="conditionsItem.relation !== 'IS_NULL' && conditionsItem.relation !== 'IS_NOT_NULL' && conditionsItem.type !== 'ALL' && conditionsItem.type !== 'SYSTEM_ACCOUNT'">
            <template v-if="conditionsItem.type === 'STAFF_OR_DEPARTMENT'">
              <template v-if="conditionsItem.expect && conditionsItem.expect.length > 0">
                <span class="have-checked-intent" style="cursor: pointer;color:#606266;"
                      v-for="(tagCell, tagCellIndex) in conditionsItem.expect" :key="tagCellIndex">
                  <open-data :type="tagCell.type == 1 ? 'departmentName': 'userName'"
                             :openid="tagCell.name" v-if="isOpenData()"></open-data>
                  <span v-else>{{ tagCell.name }}</span>
                  <span v-if="tagCellIndex + 1 < conditionsItem.expect.length">,</span>
                </span>
              </template>
              <div class="placeholder-text" v-else style="cursor: pointer;">
                {{ $t("common.selectPlaceholder") }}
              </div>
            </template>
            <!-- 下拉选择自定义字段的值 -->
            <el-select
                class="choose-tag-select"
                v-else-if="conditionsItem.type === 'CUSTOMIZE'"
                size="small"
                v-model="conditionsItem.expect"
                filterable
                multiple
                collapse-tags
                :placeholder="$t('botLink.inputOrSelect')"
                style="width: 100%">
              <el-option
                  v-for="item in tagsOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
              </el-option>
            </el-select>
            <!-- 指定账号 -->
            <el-select
                v-model="conditionsItem.expect"
                filterable
                multiple
                collapse-tags
                reserve-keyword
                placeholder="请输入"
                @scroll.native="handleScroll"
                v-else-if="conditionsItem.type === 'SYSTEM_DES_ACCOUNT'"
                style="width: 100%"
            >
              <el-option
                  v-for="item in accountList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
              <div v-if="hasNextPage" class="load-more" @click="loadMore">
                点击加载更多
              </div>
            </el-select>
            <!-- 指定账号组 -->
            <el-select
                class="choose-tag-select"
                v-else-if="conditionsItem.type === 'SYSTEM_DES_ACCOUNT_GROUP'"
                size="small"
                v-model="conditionsItem.expect"
                filterable
                multiple
                collapse-tags
                :placeholder="$t('botLink.inputOrSelect')"
                style="width: 100%">
              <el-option
                  v-for="item in userGroup"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <!-- 指定账号角色 -->
            <el-select
                class="choose-tag-select"
                v-else-if="conditionsItem.type === 'SYSTEM_DES_ACCOUNT_ROLE'"
                size="small"
                v-model="conditionsItem.expect"
                filterable
                multiple
                collapse-tags
                :placeholder="$t('botLink.inputOrSelect')"
                style="width: 100%">
              <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </template>
      </div>
      <div  class="add-condition-bot-btn"
            v-if="conditionsIndex === 0"
            @click="addCondition(conditionsItem)"
      >
        <i class="iconfont guoran-tongyichicun-jiahao"></i>
      </div>
      <div :class="['del-ondition']" v-if="conditionsIndex !== 0">
        <i class="iconfont guoran-tongyichicun-16-09-shanchu2"
           @click="delCondition(conditionsItem,conditionsIndex)"></i>
      </div>
      <div class="and-box" v-if="conditionsIndex !== conditions.length - 1">{{$t('botLink.or')}}</div>
    </div>
    <div class="consultation-scope-top" :style="{width: 'calc(100% - 25px)'}"
         v-if="conditions.length == 0">
      <div class="select-cell-placeholder"  @click="openConditionSelection">
        <span >{{$t('common.selectPlaceholder')}}</span>
      </div>
    </div>
    <!-- 选择咨询者 -->
    <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
      <div slot="popup-name">{{ $t("botLink.dialogMemberTitle") }}</div>
      <div slot="popup-con" class="member-contanier">
        <div class="choose-style" v-if="staffCustomerType === 'knowledge' && false">
          <div class="choose-title">{{ $t("botLink.dialogMemberTip") }}</div>
          <div class="radio-box">
            <el-radio v-model="chooseStyle" label="all">{{ $t("botLink.askbotClientDrawer.all") }}</el-radio>
            <el-radio v-model="chooseStyle" label="self">{{ $t("botLink.askbotClientDrawer.custom") }}</el-radio>
          </div>
        </div>
        <staffCustomer
            dataType="staff"
            v-if="chooseStyle === 'self'"
            :isMultiple="true"
            @onNodeClick="onNodeClick"
            :memberInfo4Client="staffCustomerType === 'bindBot' ? activeItem.memberInfo4Client : memberInfo4Client"
            ref="staffCustomer"></staffCustomer>
      </div>
      <div slot="dialog-footer">
        <el-button @click="cancelChooseMember" plain class="cancel-btn">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="preserStaff" class="confirm-btn">{{ $t("common.confirm") }}</el-button>
      </div>
    </popup>
  </div>
</template>

<script>
import OpenData from "../../../components/openData";
import Popup from "../../../components/popup";
import StaffCustomer from "../../companyManage/staffManage/components/staffcustomerNew";
export default {
  name: "permission",
  components: { StaffCustomer, Popup, OpenData },
  data(){
    return{
      conditions:[
        {
          expect:[],
          type:"ALL",
          key:"",
          relation:null
        }
      ],
      userAttributeOptions:[{
        value:"ALL",
        label:"所有人",
      },{
        value:"STAFF",
        label:"员工",
        children:[{
          value:"STAFF_OR_DEPARTMENT",
          label:"员工 / 部门"
        },{
          value:"CUSTOMIZE",
          label:"标签",
          key: 'label',
          type: "SELECT"
        }]
      },
        {
          value:"SYSTEM_ACCOUNT",
          label:"所有系统账号"
        },
        {
          value:"SYSTEM_DES_ACCOUNT",
          label:"系统账号-指定账号"
        },
        {
          value:"SYSTEM_DES_ACCOUNT_GROUP",
          label:"系统账号-指定账号组"
        },
        {
          value:"SYSTEM_DES_ACCOUNT_ROLE",
          label:"系统账号-指定账号角色"
        }
      ],
      attrRelations: [
        { name: "包含任意", value: "CONTAIN_ANY" },
        { name: "不包含任意", value: "NOT_CONTAIN_ANY" },
      ],
      activeConditionsIndex: 0,
      dialogMemberTree:false,
      staffCustomerType: "bindBot",
      chooseStyle: "self",
      memberInfo4Client:{},
      activeItem:{},
      chooseStyleOld: "all",
      noAskbotBind:true
    }
  },
  props:{
    tagsOptions: {
      type: Array,
      default() {
        return [];
      }
    },
    orgTree: {
      type: Array,
      default() {
        return [];
      }
    },
    userGroup:{
      type: Array,
      default() {
        return [];
      }
    },
    roleList:{
      type: Array,
      default() {
        return [];
      }
    },
    accountList:{
      type: Array,
      default() {
        return [];
      }
    },
    hasNextPage:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    onNodeClickCondition(data, conditionsItem) {
      conditionsItem.visible = false;
      if (data.value === "STAFF") {
        conditionsItem.visible = true;
        return false;
      }
      ;
      conditionsItem.type = data.value;
      conditionsItem.key = "";
      conditionsItem.expect = [];
      if (data.value === 'ALL' || data.value === 'SYSTEM_ACCOUNT'){
        conditionsItem.relation = null
      } else {
        conditionsItem.relation = 'CONTAIN_ANY'
      }
      if (data.value === "CUSTOMIZE") {
        conditionsItem.key = data.key;
      }
    },
    openStaffPopup(conditionsItem, conditionsIndex) {
      this.activeConditionsIndex = conditionsIndex;
      this.staffCustomerType = this.noAskbotBind ? "knowledge" : "bindBot";
      if (this.staffCustomerType === "bindBot") {
        this.activeItem.checkedList = conditionsItem.expect ? JSON.parse(JSON.stringify(conditionsItem.expect)) : [];
        this.chooseStyle = "self";
        this.activeItem.memberInfo4Client.clientInfo = this.activeItem.conditions[this.activeConditionsIndex].expect;
      } else {
        this.memberInfo4Client.clientInfo = conditionsItem.expect ? JSON.parse(JSON.stringify(conditionsItem.expect)) : [];
        this.chooseStyle = 'self';
      }
      this.dialogMemberTree = true;
    },
    addCondition() {
      this.conditions.push({
        visible: false,
        type: "ALL",
        expect: [],
        key: "",
        relation: "CONTAIN_ANY"
      });
    },
    delCondition(conditionsItem, conditionsIndex) {
      this.conditions.splice(conditionsIndex, 1);
    },
    openConditionSelection() {
      this.conditions.push({
        visible: true,
        type: "",
        expect: [],
        key: "",
        relation: ""
      });
      setTimeout(() =>{
        // item.conditions[0].visible = true
      },200)
    },
    //部门是否使用通讯录组件
    isOpenData() {
      /* const res = /^\d+$/;
       console.debug('value',res.test(value))*/
      let bool = false;
      if (this.orgTree.length > 1) {
        if (this.orgTree[0].originData &&
            this.orgTree[0].originData.wechatBotDTO &&
            this.orgTree[0].originData.wechatBotDTO.bindType === 0 &&
            this.orgTree[0].originData.wechatBotDTO.wechatType === 0) {
          bool = true;
        } else if (this.orgTree[1].originData &&
            this.orgTree[1].originData.wechatBotDTO &&
            this.orgTree[1].originData.wechatBotDTO.bindType === 0 &&
            this.orgTree[1].originData.wechatBotDTO.wechatType === 0) {
          bool = true;
        }
      } else if (this.orgTree.length === 1) {
        if (this.orgTree[0].originData &&
            this.orgTree[0].originData.wechatBotDTO &&
            this.orgTree[0].originData.wechatBotDTO.bindType === 0 &&
            this.orgTree[0].originData.wechatBotDTO.wechatType === 0) {
          bool = true;
        }
      }
      return bool;
    },
    onNodeClick(node) {
      let obj = JSON.parse(JSON.stringify(node));
      obj.name = obj.label || obj.name;
      let AddObj = {
        id: obj.id,
        name: obj.label,
        type: obj.type,
        isWeWorkThirdData: obj.isWeWorkThirdData
      };
      if (obj.checked) {
        obj.ewechat = obj.staffType === "staff-t" ? true : false;
        if (this.staffCustomerType === "bindBot") {
          if (this.activeItem.checkedList && this.activeItem.checkedList.length > 0) {
            this.activeItem.checkedList.push(AddObj);
          } else {
            this.activeItem.checkedList = [AddObj];
          }
          this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList);
        } else {
          this.memberInfo4Client.clientInfo.push(AddObj);
          this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo);
        }
      } else {
        if (this.staffCustomerType === "bindBot") {
          this.activeItem.checkedList.forEach((v, i) => {
            if (obj.id == v.id) {
              this.activeItem.checkedList.splice(i, 1);
            }
          });
          this.activeItem.checkedList = this.handlerArr(this.activeItem.checkedList);
        } else {
          this.memberInfo4Client.clientInfo.forEach((v, i) => {
            if (obj.id == v.id) {
              this.memberInfo4Client.clientInfo.splice(i, 1);
            }
          });
          this.memberInfo4Client.clientInfo = this.handlerArr(this.memberInfo4Client.clientInfo);
        }
      }
      this.$refs.staffCustomer.checkMemberList = [];
    },
    handlerArr(tempArr) {
      let result = [];
      let obj = {};
      for (let i = 0; i < tempArr.length; i++) {
        if (!obj[tempArr[i].id]) {
          result.push(tempArr[i]);
          obj[tempArr[i].id] = true;
        }
        ;
      }
      ;
      return result;
    },
    cancelChooseMember() {
      this.chooseStyle = 'self';
      this.dialogMemberTree = false;
    },
    preserStaff() {
      if (this.chooseStyle === "all") {
        this.memberInfo4Client.clientInfo = [];
      }
      if (this.chooseStyle === "all") {
        this.memberInfo4Client = {
          clientInfo: []
        };
        this.conditions[this.activeConditionsIndex].expect = [];
        this.conditions[this.activeConditionsIndex].type = "ALL";
      } else {
        this.conditions[this.activeConditionsIndex].expect = this.staffCustomerType === "bindBot" ? this.activeItem.checkedList : this.memberInfo4Client.clientInfo;
      }
      this.dialogMemberTree = false;
    },
    async remoteMethod() {
      this.$emit('')
    },
    // 加载更多数据
    async loadMore() {
      if (this.loading || !this.hasNextPage) return;
      this.loading = true;
      this.$emit('loadMore')
    },

    // 滚动事件处理
    handleScroll({ target }) {
      const { scrollTop, scrollHeight, clientHeight } = target;
      const threshold = 50; // 距离底部多少像素触发加载

      if (scrollHeight - (scrollTop + clientHeight) < threshold) {
        this.loadMore();
      }
    },
  }
};
</script>

<style scoped lang="less">
.permission{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  .consultation-scope-top {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
    position: relative;
    .consultation-scope-top-content {
      min-height: 36px;
      border: 1px solid #DCDFE6;
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex: 1;
      /deep/ .el-select__input {
        border: none;
      }

      /deep/ .el-input__inner {
        border: none;
      }
    }
    .error-content {
      border: 1px solid red;
      width: calc(100% - 25px);
      flex: none;
    }
    .del-ondition {
      margin-left: 10px;
      color: #366AFF;
      cursor: pointer;
    }

    .select-cell-et {
      flex: none;
      width: 200px;
    }

    .select-cell-eteq {
      flex: none;
      width: 100px;
    }

    .select-cell-etv {
      flex: auto;
    }

    .select-cell-attr {
      flex: none;
      width: 170px;
      display: flex;
      align-items: center;

      .choose-user-attrible {
        text-align: left;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 0 15px;
        color: #606266;

        &.empty-user-attrible {
          color: #d5d5d5;
        }
      }
    }

    .select-cell-attreq {
      flex: none;
      width: 90px;
      display: flex;
      align-items: center;

      /deep/ .el-input {
        .el-input__inner {
          border: none;
          box-shadow: none;
          padding: 0 10px;
        }

        .el-select__caret {
          display: none;
        }
      }
    }

    .select-cell-attrv {
      flex: auto;
      // display: flex;
      // align-items: center;
      // padding: 6px;
      // width: 100%;
      line-height: 36px;

      .attr-value {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .select-cell-attrv-placeholder {
        color: #c0c4cc;
        cursor: pointer;
      }

      .choose-tag-select {
        .el-input__inner {
          border: none;
        }
      }
      .placeholder-text{
        color: #C0C4CC!important;
        font-size: 13px;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
      }
    }
    .select-cell-placeholder {
      min-height: 36px;
      border: 1px solid red;
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex: 1;
      color: #606266;
      cursor: pointer;
      padding-left: 12px;
    }
  }
  .add-condition-bot-btn {
    cursor: pointer;
    margin-left: 10px;
    i{
      color: #366aff;
    }

    span {
      cursor: pointer;
      color: #366AFF;
    }
  }
  .and-box{
    position: absolute;
    left: -10px;
    top: 30px;
    width: 18px;
    height: 18px;
    background: #366AFF;
    font-size: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    color: #ffffff;
    z-index: 99;
  }
  .choose-title{
    margin-bottom: 10px;
  }
}
.load-more{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  cursor: pointer;
}
 /deep/#popup-assembly {
  width: 800px !important;

  .choose-style {
    text-align: left;

    > div {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }

    .radio-box {
      height: 40px;
      background: #FBFCFD;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 20px;

      .el-radio {
        width: 40%;
      }
    }
  }
}
</style>