<template>
  <div class="channelView">
    <div class="gr-content-container">
      <div class="personalHeader">
        <top-header>
          <div slot="setup-header-left" class="setup-header-left">
            <span class="setup-header-left-icon">
              <i class="iconfont guoran-tongyichicun-qudaojieru"></i
              ></span>
            <span>{{$t('channel.title')}}</span>
          </div>
        </top-header>
      </div>
      <div class="content">
        <div class="channelLeft">
          <div class="navList">
            <div v-for="(item,index) in navList"
                 :key="index"
                 :class="['navItem',activeNav == item.id ?'activeNavItem':'']"
                 @click="activeNav = item.id"
            >
              <i class="iconfont" :class="item.icon"></i>
              {{$t('channel.' + item.id)}}
              <div class="to_be_update" v-if="item.id == 'channelSuccess' && toBeUpdateFlag">{{ $t("common.toBeImproved") }}</div>
            </div>
          </div>
        </div>
        <div class="channelRight">
          <channel-success
              v-if="activeNav == 'channelSuccess'"
              :activeNav.sync="activeNav"
              @access="access"
              ref="channelSuccess"
          ></channel-success>
          <channel-access v-if="activeNav == 'channelAccess'"></channel-access>
          <intelligent-agent-manage v-if="activeNav == 'intelligentAgent'" ref="intelligentAgentManage"></intelligent-agent-manage>
          
        </div>
      </div>
    </div>
    <SideNav ref="leftMenuObj"></SideNav>
  </div>
</template>

<script>
import channelSuccess from "@/views/channelManage/channelSuccess";
import channelAccess from "@/views/channelManage/channelAccess";
import TopHeader from "@/components/top-header";
import SideNav from "@/views/menu/SideNav";
import IntelligentAgentManage from "./intelligentAgentManage";
export default {
  name: "channelView",
  components: { IntelligentAgentManage, SideNav, TopHeader, channelSuccess, channelAccess },
  data(){
    return{
      navList:[
        {
          name:'已接入',
          icon:"guoran-tongyichicun-yijieru",
          id:'channelSuccess'
        },
        {
          name:'接入渠道',
          icon:"guoran-tongyichicun-qudaojieru",
          id:'channelAccess'
        },
        {
          name:'智能体管理',
          icon:"guoran-AskAI-logo-dinggao",
          id:'intelligentAgent'
        }
      ],
      activeNav:"channelSuccess",
      alertTips:'',
    }
  },
  computed: {
    toBeUpdateFlag() {
        return this.$store.state.toBeUpdateList.length != 0
    }
  },
  mounted(){
    this.activeNav = this.$route.query.type || 'channelSuccess'
  },
  methods:{
    //点击去接入按钮跳转到接入渠道页面
    access(){
      this.activeNav = 'channelAccess'
    },
   
  },
  watch:{
    "$route":{
      handler(to,from){
        console.log('$route',to,from)
        if (from.path === '/accessDetails' && this.$refs.channelSuccess){
          this.$refs.channelSuccess.getList();
        }
      }
    }
  },
  activated() {
    let gotoFlag = sessionStorage.getItem('gotoFlag');
    if (gotoFlag === 'config'){
      this.activeNav = 'channelSuccess';
      sessionStorage.removeItem('gotoFlag');
    }
    let addIntelligentAgentDialog = sessionStorage.getItem('addIntelligentAgentDialog');
    if (addIntelligentAgentDialog){
      this.activeNav = 'intelligentAgent';
      this.$nextTick(() => {
        this.$refs.intelligentAgentManage.addIntelligent();
        sessionStorage.removeItem('addIntelligentAgentDialog');
      })
    }
    // this.getToBeUpdateList()
  }
};
</script>

<style scoped lang="less">
.channelView{

  .gr-content-container {
    position: absolute;
    left: 64px;
    width: calc(100% - 96px);
    height: calc(100% - 24px);
    padding: 12px 16px;
    min-width: 1000px;
    overflow-x: auto;
    text-align: left;
    .content {
      width: 100%;
      height: calc(100% - 80px);
      overflow: hidden;
    }
  }
  .channelLeft{
    flex: none;
    width: 220px;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
    border-radius: 5px;
    margin-right: 16px;

    .navList{
      padding: 20px 0;
      .navItem{
        padding: 14px 20px;
        cursor: pointer;
        border-left: 2px solid #FFFFFF;
        position: relative;
        i{
          margin-right: 10px;
          font-size: 14px;
        }
        .to_be_update {
            position: absolute;
            padding: 2px 6px;
            font-size: 12px;
            background: red;
            color: #FFFFFF;
            right: 10px;
            top: 14px;
            border-radius: 10px;
            font-weight: 600;
        }
      }
      .activeNavItem{
        background-color: rgba(54,106,255,.07);
        color: #366aff;
        border-left: 2px solid #366aff;
      }
    }
  }
  .channelRight{
    flex: 1;
    height: 100%;
    position: relative;
    .tips{
      padding: 8px 16px;
      color: #e6a23c;
      background-color: #fdf6ec;
      border-radius: 4px;
      display: flex;
      align-items: center;
      i{
        margin-right: 12px;
      }
    }
  }
}
</style>