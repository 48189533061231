<template>
    <div style="
            background-color: white;
            height: 100vh;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        ">
        <div style="width: 5%">
            <img src="../../assets/images/loading.gif" style="width: 100%" />
        </div>
    </div>
</template>
<script>
import { redirectUri } from "../../const/redirectUri";
import { redirect2LoginPage } from "../../const/redirectUri";
import { doReSetStorage } from "../../api/auth";

export default {
    beforeMount () {
        this.getToken()
    },
    methods: {
        getToken() {
            debugger
            let token = this.$route.query.token;
            let isAskLightning = this.$route.query.isAskLightning;
            let link = this.$route.query.link
            var instance = axios.create();
            console.log(token, 'token=====');
            if (isAskLightning != null && isAskLightning != "") {
                redirect2LoginPage(isAskLightning, token);
                if (link) {
                    // 记录标识，从知识客户端跳转过来需要去往的地址
                    sessionStorage.setItem('fromWebToLink', link);
                }
            } else {
                if (token != null && token != "") {
                    console.log("auth: 37");
                    sessionStorage.removeItem('systemLanguage');
                    let onAuhSuccess = (datas) => {
                        sessionStorage.setItem("_token", token)
                        console.log(datas, 11111);
                        if (sessionStorage.getItem('fromWebToLink')) {
                            let link = sessionStorage.getItem('fromWebToLink');
                            this.$router.push({ path: "/" + link });
                            sessionStorage.removeItem('fromWebToLink');
                            return
                        }
                        // 判断是否同源，表示进入的个人中心，不走权限校验逻辑
                        if (process.env.NODE_ENV == 'production') {
                            if (document.referrer != '' && !document.referrer.includes('portal') && !document.referrer.includes('signin') && !document.referrer.includes('wisdom')) {
                                this.$router.push('/personalContent')
                                return
                            }
                        }
                        // 根据权限判断跳转
                        let rolePrivilegesList = JSON.parse(
                            localStorage.getItem("rolePrivileges")
                        );

                        let enterManage = 0,
                            HAS_PORTAL_MANAGEMENT = false,
                            HAS_PORTAL_ENTRANCE_OPEN = false,
                            HAS_PORTAL_ENTRANCE_IM = false,
                            HAS_PORTAL_ENTRANCE_WORKORDER = false,
                            HAS_PORTAL_ENTRANCE_KNOWLEDGE = false;
                        // HAS_PORTAL_ENTRANCE = false;
                        console.log("auth: 49");
                        rolePrivilegesList.forEach((item) => {
                            // // 管理系统入口权限
                            // if (item.id === "PORTAL_ENTRANCE") {
                            //     HAS_PORTAL_ENTRANCE = item.checked;
                            //     enterManage += Number(item.checked);
                            // }
                            // 管理
                            if (item.id === "PORTAL_MANAGEMENT") {
                                HAS_PORTAL_MANAGEMENT = item.checked;
                            }
                            // 机器人
                            if (item.id === "PORTAL_ENTRANCE_OPEN") {
                                HAS_PORTAL_ENTRANCE_OPEN = item.checked;
                                enterManage += Number(item.checked);
                            }
                            // IM协同
                            if (item.id === "PORTAL_ENTRANCE_IM") {
                                HAS_PORTAL_ENTRANCE_IM = item.checked;
                                enterManage += Number(item.checked);
                            }
                            // 工单
                            if (item.id === "PORTAL_ENTRANCE_WORKORDER" || item.id === "WORK_ORDER_SYSTEM_NAV") {
                                HAS_PORTAL_ENTRANCE_WORKORDER = item.checked;
                                enterManage += Number(item.checked);
                            }
                            // 企业知识管理系统入口权限
                            if (item.id === "PORTAL_ENTRANCE_KNOWLEDGE") {
                                HAS_PORTAL_ENTRANCE_KNOWLEDGE = item.checked;
                                enterManage += Number(item.checked);
                            }
                        });
                        console.log("auth: 71", enterManage);
                        if (enterManage >= 2 || HAS_PORTAL_MANAGEMENT) {
                            // 有管理系统权限，跳转管理系统
                            localStorage.setItem('isShowOther', false)
                            console.log(localStorage.getItem('isShowOther'), 'isShowOther11111');
                            if (datas.data.data.languageCode) {
                                sessionStorage.setItem('systemLanguage', datas.data.data.languageCode);
                                this.$i18n.locale = datas.data.data.languageCode;
                                // this.$i18n.locale = "en"
                                sessionStorage.removeItem('noLanguageCode');
                                if (datas.data.data.source == 5 && datas.data.data.isDefault && datas.data.data.roleName === '上下游负责人') {
                                    localStorage.setItem('isShowIndex', false);
                                    this.$router.push({ name: "_upDownManage" });
                                } else {
                                    localStorage.setItem('isShowIndex', true);
                                    this.$router.push({ name: "Index" });
                                }
                            } else {
                                //这个设置是为了区分是否账号设置了语言，noLanguageCode为null时系统语言进行设置时要更新页面
                                sessionStorage.setItem('noLanguageCode', true)
                                //该接口获取系统语言
                                this.$http.get(this.requestUrl.companyManage.getUserCompany).then(res => {
                                    if (res.data.code == 0 && res.data.data && res.data.data.systemLanguage) {
                                        sessionStorage.setItem('systemLanguage', res.data.data.systemLanguage);
                                        this.$i18n.locale = res.data.data.systemLanguage
                                        // this.$i18n.locale = "en"
                                    }
                                    console.log("auth: 74");
                                    // this.$router.push({ name: "Index" });
                                    // source == 5 上下游负责人
                                    if (datas.data.data.source == 5 && datas.data.data.isDefault && datas.data.data.roleName === '上下游负责人') {
                                        localStorage.setItem('isShowIndex', false);
                                        this.$router.push({ name: "_upDownManage" });
                                    } else {
                                        localStorage.setItem('isShowIndex', true);
                                        this.$router.push({ name: "Index" });
                                    }
                                })
                            }
                            // if (
                            //     res.data.turnOn &&
                            //     wlMainIds.indexOf(
                            //         localStorage.getItem("_mainId")
                            //     ) !== -1
                            // ) {
                            //     console.log("auth: 81");
                            //     this.$router.push({ name: "Index" });
                            // } else {
                            //     console.log("auth: 84");
                            //     this.$router.push({ name: "Home" });
                            // }
                        } else {
                            console.log("auth: 88");
                            // let tempwindow = window.open("_blank");
                            if (HAS_PORTAL_ENTRANCE_OPEN) {
                                console.log("auth:95");
                                // tempwindow.location = `https://${dev_str}open.askbot.cn`;
                                window.location.href = process.env.VUE_APP_OPEN_URL;
                            } else {
                                console.log("auth: 98");
                                if (HAS_PORTAL_ENTRANCE_IM) {
                                    console.log("auth: 100");
                                    // tempwindow.location = `http://${dev_str}im.askbot.cn`;
                                    window.location.href = process.env.VUE_APP_IM_SERVER_URL;
                                } else {
                                    console.log("auth: 103");
                                    if (HAS_PORTAL_ENTRANCE_WORKORDER) {
                                        console.log("auth: 105");

                                        // tempwindow.location = `http://workorder.${dev_str}askbot.cn`;
                                        window.location.href = process.env.VUE_APP_WORK_ORDER_URL;
                                    } else {
                                        if (HAS_PORTAL_ENTRANCE_KNOWLEDGE) {
                                            console.log("auth: 105");
                                            // tempwindow.location = `http://workorder.${dev_str}askbot.cn`;
                                            window.location.href = process.env.VUE_APP_WISDOM;
                                        }
                                    }
                                }
                            }
                        }

                        if (!HAS_PORTAL_MANAGEMENT && !HAS_PORTAL_ENTRANCE_OPEN && !HAS_PORTAL_ENTRANCE_IM && !HAS_PORTAL_ENTRANCE_WORKORDER && !HAS_PORTAL_ENTRANCE_KNOWLEDGE) {
                            this.$route.push('/')
                        }
                    };
                    let onAuhException = () => {
                        this.$message({
                            type: "error",
                            message: "token校验异常,请尝试退出重新登录",
                            duration: 2000,
                        });
                    };
                    doReSetStorage(onAuhSuccess, onAuhException, token);
                } else {
                    redirect2LoginPage();
                }
            }
        },
        getParentUrl() {
            var url = null;
            if (parent !== window) {
                try {
                    url = parent.location.href;
                } catch (e) {
                    url = document.referrer;
                }
            }
            return url;
        }
    },
    watch: {
        // '$route.query.token': {
        //     handler(value) {
        //         console.log(this.$route.query);
        //         this.getToken()
        //     },
        //     immediate: true
        // }
    }
};
</script>
