<template>
    <div class="add-dept">
        <popup @closeEvent="closeEvent" v-if="addDeptVisible">
            <div slot="popup-name" class="popup-name">{{headerTitle}}</div>
                <div slot="popup-tip">{{headerTitle}}</div>
            <div slot="popup-con">
            <el-form :model="ruleForm" ref="ruleForms" label-position="top" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="名称" prop="name">
                        <span slot="label">{{$t('addDept.deptName')}}<span class="red">*</span></span>
                        <el-input v-model="ruleForm.name" :placeholder="$t('addDept.namePlaceholder')" ></el-input>
                    </el-form-item>
                    <el-form-item class="batch-edit" label="所属部门">
                        <span slot="label">{{$t('addDept.organization')}}<span class="red">*</span></span>
                        <el-cascader
                            popper-class="choose-dept-cascader"
						 	ref="cascaderCascader"
                            :props="props"
						 	v-model="ruleForm.organizationId"
						 	style="width:100%"
							:options="treeDataCopy"
                            :show-all-levels="false">
                        </el-cascader>
					</el-form-item>
                    <el-form-item :label="$t('staffManage.setSystemPermissions')">
                        <el-checkbox v-model="ruleForm.botAuth" :disabled="ruleForm.authExtendParent" :label="$t('staffManage.bot')"></el-checkbox>
						<el-checkbox v-model="ruleForm.bigModel" :disabled="ruleForm.authExtendParent" :label="$t('staffManage.bigModelCapability')"></el-checkbox>
                        <el-checkbox v-model="ruleForm.workOrderAuth" :disabled="ruleForm.authExtendParent" :label="$t('staffManage.workOrder')"></el-checkbox>
						<el-checkbox v-model="ruleForm.authExtendParent" :label="$t('addDept.followTheSuperiorDepartment')" @change="authExtendParentChange"></el-checkbox>
					</el-form-item>
            </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round >{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="submitForm('ruleForms')" round >{{$t('common.save')}}</el-button>
            </div>
        </popup>
        <popup @closeEvent="deleteMemberDialog = false" v-if="deleteMemberDialog">
            <div slot="popup-name" class="popup-name">{{$t('addDept.deleteDeptTitle')}}</div>
            <div slot="popup-tip">{{$t('addDept.deleteDeptTip')}}</div>
            <div slot="popup-con" class="ruleForms">
                <el-form  ref="ruleForms" label-position="top" label-width="80px" class="delete-dept">
                <el-form-item :label="$t('addDept.formItem')" >
                    <el-radio v-model="radio" label="remove"> {{$t('addDept.moveTo')}}</el-radio>
                    <el-radio v-model="radio" label="del"> {{$t('addDept.delete')}}</el-radio>
                </el-form-item>
                <el-form-item label="" v-if="radio === 'remove'">
                    <el-tree
                        key="chooseDept"
                        ref="chooseDept"
                        :props="props"
                        :data="treeDataCopy"
                        @node-click="onNodeClick"
                        :expand-on-click-node="false"
                        :default-expand-all="true">
                    <span
                        class="custom-tree-node-choose-dept"
                        slot-scope="{data}">
                        <span class="custom-tree-node-left">
                        <span class="data-label" >{{data.label}}</span>
                        </span>
                        <span v-show="data.checked">
                        <i class="el-icon-check"></i>
                        </span>
                    </span>
                    </el-tree>
                </el-form-item>
                <div class="del-tips" v-else>
                    {{$t('addDept.deleteDeptTips2')}}
                </div>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeDelPopup" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="deleteFolder" round  class="confirm-btn">{{$t('common.delete')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import Popup from '../../../../components/popup.vue';
import { filterTree } from "../utils/formatTree";
export default {
    data(){
        return {
            headerTitle:'添加同级部门',
            ruleForm:{
                name:'',
                organizationId:null,
                botAuth:true,
                workOrderAuth:false,
                bigModel:false,
                authExtendParent:true,
                editOrganizationIdDisabled:false,

            },
			editNameDisabled:true,
            props:{
                value:"id",
                label:"label",
                leaf:"leaf",
                children:'children',
                checkStrictly: true 
            },
            radio:'remove',
            checkedDept:{},
            treeDataCopy:{},
            delDeptMoveTo:{}
        }
    },
    components:{
        Popup,

    },
   props:{
		addDeptVisible:{
			type:Boolean,
			default:false
		},
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
        deleteMemberDialog:{
            type:Boolean,
			default:false
        },
        activeTreeItem:{
            type:Object,
            default(){
                return {}
            } 
        },
        addDeptType:{
            type:String,
			default:''
        },
        corpId:{
            type:String,
			default:''
        },
        activeTreeItemNode:{
            type:Object,
            default(){
                return {}
            } 
        }
	},
    watch:{
        addDeptType(n){
            if(n !== ''){
                this.treeDataCopy = this.handlerTree(JSON.parse(JSON.stringify(this.treeData)));
            }
        },
        addDeptVisible(n,o){
            if(n){
                console.log(this.treeData,'treeData');
                if(this.addDeptType === 'addSameDept' || this.addDeptType === 'addChildDept'){
                    this.ruleForm = {
                        name:'',
                        organizationId:null,
                        botAuth:false,
                        workOrderAuth:false,
                        bigModel:false,
                        authExtendParent:true,
                        editOrganizationIdDisabled:false,
                    }
                    if(this.addDeptType === 'addSameDept'){
                        this.ruleForm.organizationId = this.activeTreeItemNode.parent.data.id;
                    } else {
                        this.ruleForm.organizationId = this.activeTreeItem.id;
                    }
                } else if (this.addDeptType === 'edit'){
                    this.ruleForm = {
                        name:this.activeTreeItem.label,
                        organizationId:this.activeTreeItemNode.parent.data.id,
                        botAuth:this.activeTreeItem.botAuth,
                        workOrderAuth:this.activeTreeItem.workOrderAuth,
                        bigModel:this.activeTreeItem.bigModel,
                        authExtendParent:this.activeTreeItem.authExtendParent || false,
                        editOrganizationIdDisabled:this.activeTreeItem.authExtendParent || false,
                        parentId:this.activeTreeItem.id
                    }
                    if(this.activeTreeItem.authExtendParent){
                        this.ruleForm.botAuth = false;
                        this.ruleForm.workOrderAuth = false;
                        this.ruleForm.bigModel = false;
                    }
                }

                // 修改弹窗标题
                if (this.addDeptType === 'addSameDept') {
                    this.headerTitle = this.$t('addDept.addSameDepart');
                } else if (this.addDeptType === 'addChildDept') {
                    this.headerTitle = this.$t('addDept.addChildDept');
                } else if (this.addDeptType === 'edit') {
                    this.headerTitle = this.$t('addDept.editDept');
                }
            }
            console.log(this.ruleForm,'this.ruleForm');
        },
    },
    computed:{
        deptList(){
            return this.treeData; 
        }
    },
    methods:{
        
        onNodeClick(data,node){
            console.log(data,node);
            this.delDeptMoveTo = data;
            this.getAllNodeId(this.treeDataCopy,data);
        },
         // 递归获取所有节点
        getAllNodeId (moduleDataList,data) {
            return new  Promise((resolve) => {
                if (moduleDataList){
                for (let i = 0; i < moduleDataList.length; i++) {
                    if(data.id === moduleDataList[i].id){
                    this.$set(moduleDataList[i],'checked',!moduleDataList[i].checked);
                    } else {
                    this.$set(moduleDataList[i],'checked',false);
                    }
                    if (moduleDataList[i].children && moduleDataList[i].children.length > 0) {
                    this.getAllNodeId( moduleDataList[i].children,data)
                    }
                }
                }
                resolve(true)
            })
        },
        closeDelPopup(){
            this.$emit('update:deleteMemberDialog',false)
        },
         // 递归取出checked=true的数据
        judgeChildrens(data) {
            data.forEach(item => {
                if (item.checked) {
                    this.checkedDept = item;
                }
                if (item.children && item.children.length > 0) {
                this.judgeChildrens(item.children || []);
                }
            });
            return
        },
        deleteFolder(){
            if (this.radio === 'remove'){
                this.judgeChildrens(this.treeDataCopy);
                console.log(this.checkedDept,'checkedDept');
                if(Object.keys(this.checkedDept).length === 0 && this.radio === 'remove'){
                    this.$message.warning(this.$t('addDept.deleteDeptMsg'))
                    return false;
                }
                this.$emit('deleteDept',this.delDeptMoveTo)
            } else {
                this.$emit('deleteDept')
            }
        },
        handlerTree(treeData){
            treeData.forEach((item,index) => {
                if((this.addDeptType === 'del'  || this.addDeptType === 'edit' )&& this.activeTreeItem.id == item.id){
                    item.isShow = false;
                    treeData.splice(index, 1);
                } else {
                    item.isShow = true;
                }
                if(item.children && item.children.length > 0){
                    item.leaf = false;
                    this.handlerTree(item.children)
                } else {
                    delete item.children;
                    item.leaf = true;
                }
            })
            return treeData
        },
       
        // 关闭弹框
        closeEvent(){
            this.$refs['ruleForms'].resetFields();
            this.$emit('update:addDeptVisible',false);
        },
        authExtendParentChange(){
            this.ruleForm.botAuth = false;
            this.ruleForm.bigModel = false;
            this.ruleForm.workOrderAuth = false;
        },
        // 保存
        submitForm(formName){
            console.log(this.ruleForm,'ruleForm');
            let flag = true;
            if(this.ruleForm.name === ''){
                flag = false;
                this.$message.error(this.$t('addDept.nameRequired'))
				return false;
            }
            if(this.addDeptType === 'addSameDept' || this.addDeptType === 'addChildDept' || this.addDeptType === 'edit'){
                if(!this.ruleForm.organizationId || this.ruleForm.organizationId.length === 0){
                    flag = false;
                    this.$message.error(this.$t('addDept.deptRequired'))
                    return false;
                }
            }
            if(flag){
                if(this.addDeptType === 'addSameDept' || this.addDeptType === 'addChildDept'){
                    let params1 = {
                        name: this.ruleForm.name,
                        corpId:this.corpId,
                        order:null,
                        botAuth: this.ruleForm.botAuth, // 使用机器人能力
                        workOrderAuth: this.ruleForm.workOrderAuth, // 使用工单能力
                        bigModel: this.ruleForm.bigModel, // 使用大模型能力
                        authExtendParent: this.ruleForm.authExtendParent // 跟随父级部门，需要判断添加部门的parentId是否为根部门，根部门该项不可选
                    }
                    if(typeof(this.ruleForm.organizationId) !== 'object'){
                        params1.parentId = this.ruleForm.organizationId
                    } else {
                        params1.parentId = this.ruleForm.organizationId[this.ruleForm.organizationId.length-1]
                    }
                    if(this.addDeptType === 'addSameDept'){
                       params1.order = this.activeTreeItemNode.parent.data.children.length;
                    } else {
                        params1.order = this.activeTreeItemNode.data.children.length;
                    }
                    this.$http.post(this.requestUrl.department.addDepartment,params1).then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.$emit('addDeptSuccess')
                            console.log(res,'res');
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                } else if (this.addDeptType === 'edit'){
                    let params2 = {
                        id: this.activeTreeItem.id,
                        name:this.ruleForm.name,
                        order: this.activeTreeItem.id.order,
                        corpId:this.corpId,
                        botAuth: this.ruleForm.botAuth, // 使用机器人能力
                        workOrderAuth: this.ruleForm.workOrderAuth, // 使用工单能力
                        bigModel: this.ruleForm.bigModel, // 使用大模型能力
                        authExtendParent: this.ruleForm.authExtendParent, // 跟随父级部门，需要判断添加部门的parentId是否为根部门，根部门该项不可选
                        // parentId:this.ruleForm.parentId
                    }
                    if(Array.isArray(this.ruleForm.organizationId)){
                        params2.parentId = this.ruleForm.organizationId[this.ruleForm.organizationId.length-1]
                    } else {
                        params2.parentId = this.activeTreeItemNode.parent.data.id
                    }
                    this.$http.put(this.requestUrl.department.editDepartmentById + "/" + this.activeTreeItem.id,params2).then((res) => {
                        if (res.data && res.data.code === "0") {
                            this.$emit('addDeptSuccess')
                            console.log(res,'res');
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                } else if (this.addDeptType === 'del'){
                    this.$emit('deleteDept',delDeptMoveTo)
                }
            }
        }
    },
    mounted() {
        
    },

}
</script>
<style lang="less">
.choose-dept-cascader{
    z-index: 3333 !important;
}
</style>
<style scoped lang="less">
    .add-dept{
        .el-button{
            width: 99px;
            height: 37px;
            padding: 0;
        }
        .el-button--primary{
            background-color: #366AFF;
            border-color: #366AFF;
        }
        ::v-deep .el-form--label-top .el-form-item__label{
			float: left;
			&.after{
				clear: both;
			}
		}
        .limit-contnet{
            display: flex;
            flex-direction: column;
            ::v-deep .el-input__suffix-inner span{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
            }
        }
        .red {
			color: red;
            margin-left: 6px;
		}
        ::v-deep .el-form-item{
            display: flex;
            flex-direction: column;
            .el-radio__input.is-checked .el-radio__inner{
                border-color: #366AFF;
                background: #366AFF;            
            }
            .el-radio__input.is-checked+.el-radio__label{
                color: #366AFF;
            }
        }
         .staff-input{
            // height: 36px;
            width:466px;
            border-radius: 5px;
            border:1px solid #E0E6F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            .placeholder-text{
                padding-left: 15px;
                color: #A9B3C6;
            }
            .client-value{
                padding: 5px;
                color: #606266;
                display: flex;
                // justify-content: space-around;
                flex-wrap: wrap;
                align-items: center;
                line-height: 30px;
                width: 100%;
                .item{
                    border:1px solid hsl(240, 5%, 92%);
                    height: 24px;
                    line-height: 24px;
                    padding: 0 8px;
                    line-height: 22px;
                    position: relative;
                    background-color: #ECF1FF;
                    color: #366AFF;
                    border-radius: 3px;
                    font-size: 12px;
                    margin: 2px 0 2px 6px;
                    .el-icon-close{
                        display: inline-block;
                        border-radius: 50%;
                        text-align: center;
                        position: relative;
                        cursor: pointer;
                        font-size: 12px;
                        height: 14px;
                        width: 14px;
                        line-height: 14px;
                        background-color: #c0c4cc;
                        color: #909399;
                        cursor: pointer;
                    }
                }
                

            }
            
        }
        .bot-num{
            margin-bottom: 30px;
        }
        .bot-num,.choose-num{
            height: 21px;
            display: flex;
            // justify-content: space-around;
            align-items: center;
            color: #616161;
            .icon{
                width: 21px;
                height: 21px;
                background: #09DDD5;
                border-radius: 50%;
                text-align: center;
                line-height: 21px;
                color: #fff;
                
                &.choose{
                    background-color: #87A6FF;
                }
            }
            .type{
                margin: 0 15px 0 10px;
                width: 122px;
                text-align: left;
                color: #A9B3C6;
            }
            .go-beyond{
                // width: 92px;
                padding: 0 6px;
                height: 20px;
                font-size: 12px;
                background: #FFEBEC;
                border-radius: 3px;
                color: #FE5965;
                text-align: center;
                line-height: 20px;
                margin-left: 5px;
            }

        }
        .delete-dept{
            .custom-tree-node-choose-dept{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            .del-tips{
                height: 42px;
                background: #FFF8F4;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 400;
                color: #FF9555;
                text-align: left;
                line-height: 42px;
                padding: 0 10px;
            }
        }
       
    }
</style>