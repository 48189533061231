const navList = {
    website:[
        {
            name:"接入信息",
            icon:"guoran-tongyichicun-qudaojieru",
            id:"basic"
        },
        {
            name:"用户登录",
            icon:"guoran-tongyichicun-dangqiandengluzhanghaoshu",
            id:"login"
        },
        {
            name:"能力管理",
            icon:"guoran-tongyichicun-chanpinguanli",
            id:"ability"
        },
        {
            name:"网站入口形象",
            icon:"guoran-tongyichicun-xingxiang",
            id:"entranceImage"
        },
        {
            name:"应用内信息",
            icon:"guoran-tongyichicun-yingyongxingxiang",
            id:"app"
        },
        // {
        //     name:"欢迎语",
        //     icon:"guoran-tongyichicun-huanyingyu",
        //     id:"welcomeMsg"
        // }
    ],
    wechatAccount:[
        {
            name:"接入信息",
            icon:"guoran-tongyichicun-qudaojieru",
            id:"basic"
        },
        {
            name:"用户登录",
            icon:"guoran-tongyichicun-dangqiandengluzhanghaoshu",
            id:"login"
        },
        {
            name:"能力管理",
            icon:"guoran-tongyichicun-chanpinguanli",
            id:"ability"
        },
        // {
        //     name:"欢迎语",
        //     icon:"guoran-tongyichicun-huanyingyu",
        //     id:"welcomeMsg"
        // }
    ],
    miniProgram:[
        {
            name:"接入信息",
            icon:"guoran-tongyichicun-qudaojieru",
            id:"basic"
        },
    ],
    common_1:[
        {
            name:"接入信息",
            icon:"guoran-tongyichicun-qudaojieru",
            id:"basic"
        },
        {
            name:"能力管理",
            icon:"guoran-tongyichicun-chanpinguanli",
            id:"ability"
        },
        // {
        //     name:"欢迎语",
        //     icon:"guoran-tongyichicun-huanyingyu",
        //     id:"welcomeMsg"
        // }
        {
            name:"其他",
            icon:"guoran-qita",
            id:"other"
        }
    ],
    common_2:[
        {
            name:"接入信息",
            icon:"guoran-tongyichicun-qudaojieru",
            id:"basic"
        },
        {
            name:"能力管理",
            icon:"guoran-tongyichicun-chanpinguanli",
            id:"ability"
        },
    ],
    ability:[
        {
            name:"能力管理",
            icon:"guoran-tongyichicun-chanpinguanli",
            id:"ability"
        },
    ],

}
export { navList }