<template>
  <div class="entrancePermission">
    <div class="header">
      <div class="left">
        其他
      </div>
      <div class="saveBtn" @click="save">
        <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
        {{ $t('channel.save') }}
      </div>
    </div>
    <div class="privilegesList" v-for="(item,index) in privilegesList" :key="index">
      <div class="title">{{item.name}}</div>
      <permission :ref="item.ref"
                  :tagsOptions="tagsOptions"
                  :orgTree="orgTree"
                  :userGroup="userGroup"
                  :roleList="roleList"
                  :accountList="accountList"
                  :hasNextPage="hasNextPage"
                  @loadMore="loadMore"
      ></permission>
    </div>
  </div>
</template>

<script>
import Permission from "./permission";
export default {
  name: "entrancePermission",
  components: { Permission },
  data(){
    return{
      privilegesScope:{
        "agentConditions":[],
        "orderConditions": [], // 同上
        "knowledgeConditions": [],
        "todoConditions": []
      },
      privileges:{},
      tagsOptions:[],
      orgTree:[],
      userGroup:[],
      roleList:[],
      currentPage:1,
      accountList:[],
      hasNextPage:false,
      privilegesList:[
        {
          name:"智能体入口可见权限",
          ref:"askBotPermission"
        },
        {
          name:"工单入口可见权限",
          ref:"orderPermission"
        },
        {
          name:"知识入口可见权限",
          ref:"knowledgePermission"
        },
        {
          name:"代办入口可见权限",
          ref:"representativePermission"
        }
      ]
    }
  },
  methods:{
    getPrivileges(){
      let url = '/api/bot/privileges?bindCode=' + this.$route.query.bindCode + '&botId=' + this.$route.query.id;
      this.$http.get(url).then(res => {
        if (res.data.code == 0 && res.data.data){
          this.privileges = res.data.data;
          this.privilegesScope = res.data.data.privilegesScope;
          this.$refs.askBotPermission[0].conditions = this.privilegesScope.agentConditions;
          this.$refs.orderPermission[0].conditions = this.privilegesScope.orderConditions;
          this.$refs.knowledgePermission[0].conditions = this.privilegesScope.knowledgeConditions;
          this.$refs.representativePermission[0].conditions = this.privilegesScope.todoConditions
        }
      })
    },
    save(){
      let url = '/api/bot/privileges/save';
      if (this.privileges.id){
        url = '/api/bot/privileges/updata'
      }
      this.privilegesScope.agentConditions = this.$refs.askBotPermission[0].conditions;
      this.privilegesScope.orderConditions = this.$refs.orderPermission[0].conditions;
      this.privilegesScope.knowledgeConditions = this.$refs.knowledgePermission[0].conditions;
      this.privilegesScope.todoConditions = this.$refs.representativePermission[0].conditions;
      if (this.privileges.id){
        this.privileges.privilegesScope = this.privilegesScope;
        this.$http.put(url,this.privileges).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:"修改成功",
              type:"success"
            })
          }
        })
      } else {
        let obj = {
          createId:localStorage.getItem("_uid"),
          botBindCode:this.$route.query.bindCode,
          botCorpId:localStorage.getItem('_corpId'),
          mainId:localStorage.getItem('_mainId'),
          privilegesScope:this.privilegesScope
        }
        this.$http.post(url,obj).then(res => {
          if (res.data.code == 0){
            this.$message({
              message:"保存成功",
              type:"success"
            })
          }
        })
      }
    },
    // 获取自定义标签数据
    getTagsOptions(corpId, callBack){
      this.$http.get(this.requestUrl.bindV2.getTagsOptions).then((res) => {
        console.log('getTagsOptions',res)
        if (res.data.code == 0) {
          this.tagsOptions = res.data.data;
          if (callBack) {
            let tagListIds = [];
            if (this.tagsOptions && this.tagsOptions.length != 0) {
              tagListIds = this.tagsOptions.map(item => item.id);
            }
            localStorage.setItem("ask_tagListIds", tagListIds);
            callBack(tagListIds);
          }
        }
      })
    },
    // 获取部门树
    getDepartmentTree(){
      let userId = localStorage.getItem("_uid");
      let url = "/api/department/corpListLazy" + "?userId=" + userId;
      this.$http.get(url).then(res => {
        console.log('getDepartmentTree',res)
        this.orgTree = res.data;
      })
    },
    // 获取用户分组list
    getAccountGroupList(){
      this.$http.get(this.requestUrl.accountGroup.getAccountGroupList).then(
          (res) => {
            if (res.data.code === "0") {
              this.userGroup = res.data.data;
            } else {
              this.$message.error(res.data.message);
            }
          }
      );
    },
    //获取角色列表
    getRoleList(){
      this.$http.get(this.requestUrl.role.getRoleList).then(res => {
        if (res.data && res.data.code === "0"){
          this.roleList = res.data.data;
        }
      })
    },
    //获取账号列表
    getAccount(){
      let url = this.requestUrl.accountManage.getAccountList + '?pageNo=' + this.currentPage + '&pageSize=20&status=1';
      this.$http.get(url).then(res => {
        console.log('getAccount',res)
        if (res.data.code == 0 && res.data.data){
          this.accountList = this.accountList.concat(res.data.data.list);
          this.hasNextPage = res.data.data.hasNextPage;
        }
      })
    },
    loadMore(){
      this.currentPage +=1;
      this.getAccount();
    }
  },
  mounted() {
    this.getPrivileges();
    this.getTagsOptions();
    this.getDepartmentTree();
    this.getAccountGroupList();
    this.getRoleList();
    this.getAccount();
  }
};
</script>

<style scoped lang="less">
.entrancePermission{
  height: 100%;
  overflow-y: scroll;
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      font-size: 16px;
      color: #000000;
      display: flex;
      align-items: center
    }
    .saveBtn {
      height: 26px;
      background-color: #366aff;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 14px;
      font-size: 12px;
      border-radius: 17px;
      cursor: pointer;

      i {
        font-size: 12px;
        padding-right: 4px;
      }
    }
  }
  .title{
    margin: 10px 0;
  }
  .privilegesList{
    max-width: 1000px;
  }
}
</style>