<template>
    <div class="accessDetails">
        <div class="gr-content-container">
            <div class="personalHeader">
                <detail-page-header @goBack="goBack">
                    <div slot="header-name" class="header-name">
                        <span class="template-name">{{ headerName }}</span>
                    </div>
                    <div slot="header-title-c-r-btn" class="header-c-r-btn" v-if="false">
                        <el-popover
                            placement="top-start"
                            width="100"
                            trigger="click"
                            popper-class="delete-btn"
                            v-model="deleteVisible">
                            <div class="popoverItem" @click="deleteAccess">
                                <i class="iconfont guoran-piliangshanchu"></i>
                                {{ $t('channel.delete') }}
                            </div>
                            <i slot="reference" class="iconfont guoran-qita1" v-show="channelType === 'website'"></i>
                        </el-popover>
                    </div>
                </detail-page-header>
            </div>
            <div class="detailContainer">
                <div class="leftNav">
                    <div class="navItem"
                         v-for="(item,index) in leftNavList"
                         :key="index"
                         :class="activeNavId === item.id ? 'activeNav' : ''"
                         @click="checkNav(index,item)"
                    >
                        <i :class="['iconfont ',item.icon]"></i>
                        {{ $t('channel.' + item.id) }}
                    </div>
                </div>
                <div class="rightContent">
                    <template v-if="activeNavId === 'basic'">
                        <basic-info :bindDetail="bindDetail"></basic-info>
                    </template>
                    <template v-else-if="activeNavId === 'login'">
                        <div class="needLogin">
                            <div class="header">
                                <div class="left">
                                    {{ $t('channel.login') }}
                                </div>
                                <div class="saveBtn" @click="save('login')">
                                    <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
                                    {{ $t('channel.save') }}
                                </div>
                            </div>
                            <template v-if="channelType === 'website'">
                                <div class="login">
                                    <el-switch
                                        v-model="websiteNeedLogin"
                                        @click.native.prevent="changeLoginSwitch('switch')"
                                        :disabled="bindDetail.bindBot && bindDetail.bindBot.botInfos && bindDetail.bindBot.botInfos.length > 1 && bindDetail.bindBot"
                                        active-color="#366aff"
                                        inactive-color="#E2E2E2">
                                    </el-switch>
                                    <span
                                        class="switch-open-icon"
                                        @click="changeLoginSwitch('open')"
                                        v-if="websiteNeedLogin"
                                        @click.native="clickFn"
                                    >
                                        <i class="iconfont guoran-a-16-17"></i>
                                    </span>
                                    <span
                                        class="switch-close-icon"
                                        @click="changeLoginSwitch('close')"
                                        v-if="!websiteNeedLogin">
                                        <i class="arsenal_icon arsenalcuo1"></i>
                                    </span>
                                    <div class="tips">
                                        <img src="../../assets/images/fav.png">
                                        {{ $t('channel.website.loginTip') }}
                                    </div>
                                </div>
                                <div class="loginWay">
                                    <span>{{ $t('channel.website.loginWay') }}</span>
                                    <span class="tips">
                    <i class="iconfont guoran-guanzhuren"></i>
                    {{ $t('channel.website.wayTip') }}
                  </span>
                                </div>
                                <div class="company">
                                    <el-radio-group
                                        v-model="bindCorp.id"
                                        @change="changeCompany"
                                    >
                                        <el-radio :label="item.corpId" v-for="item in loginModeOptions">
                                            {{ item.corpName }}
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                            </template>
                            <template v-else>
                                <div class="login" v-if="bindDetail.serviceType !== 0">
                                    <el-switch
                                        v-model="allowPushMsg"
                                        @click.native.prevent="changePushSwitch('switch')"
                                        :disabled="!RP_Visible('OPEN_ACCESS_PWX')"
                                        active-color="#366aff"
                                        inactive-color="#E2E2E2">
                                    </el-switch>
                                    <span
                                        class="switch-open-icon"
                                        @click="changePushSwitch('open')"
                                        v-if="allowPushMsg"
                                        @click.native="clickFn"
                                    >
                  <i class="iconfont guoran-a-16-17"></i>
                </span>
                                    <span
                                        class="switch-close-icon"
                                        @click="changePushSwitch('close')"
                                        v-if="!allowPushMsg">
                  <i class="arsenal_icon arsenalcuo1"></i>
                </span>
                                    <div class="tips">
                                        <img src="../../assets/images/fav.png">
                                        {{ $t('channel.wechatAccount.loginTip') }}
                                    </div>
                                </div>
                                <div
                                    @click="showtips"
                                    v-if="bindDetail.serviceType == 0"
                                    style="
                    margin: 0 auto;
                    width: 12px;
                     height: 12px;
                     border: 1px solid #dcdfe6;"
                                ></div>
                                <div class="loginWay">
                                    <span>{{ $t('channel.website.loginWay') }}</span>
                                    <span class="tips">
                    <i class="iconfont guoran-guanzhuren"></i>
                    {{ $t('channel.wechatAccount.wayTip') }}
                  </span>
                                </div>
                                <div class="phone">
                                    <el-radio v-model="wechatAccountLoginWay" label="phone">
                                        {{ $t('channel.wechatAccount.phoneNumber') }}
                                    </el-radio>
                                </div>
                            </template>
                        </div>
                    </template>
                    <div class="abilityManage" id="abilityManage" v-else-if="activeNavId === 'ability'">
                        <div class="header">
                            <template v-if="channelType === 'dingdingGroup'">
                                <div class="left">
                                    {{ $t('channel.dingdingGroup.header') }}
                                    <span class="dingGroupTip">{{ $t('channel.dingdingGroup.dingGroupTip') }}</span>
                                    <div class="synchronous" @click="asynToAllGroup">
                                        {{ $t('channel.dingdingGroup.sync') }}
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="left">{{ $t('channel.ability') }}</div>
                            </template>
                            <div class="saveBtn" @click="save('ability')">
                                <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
                                {{ $t('channel.save') }}
                            </div>
                        </div>
                        <bind-information :bindDetail="bindDetail" ref="bindInformation"></bind-information>
                        <ding-group-table v-if="channelType === 'dingdingGroup'" :bindDetail="bindDetail"
                                          ref="dingGroupTable"></ding-group-table>
                        <el-dropdown trigger="click" @command="gotoIntelligentAgentConfig">
                            <div class="addBindIntelligent" v-if="isBindIntelligentAgent">
                                <div class="addIcon">
                                    <i class="iconfont guoran-a-16-13"></i>
                                </div>
                                <span>{{ $t('channel.bindAgent') }}</span>
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <div class="search" v-if="false">
                                    <el-input v-model="keyword" :placeholder="$t('common.search')"
                                              @keyup.enter.native.prevent.stop="search"
                                              @input="searchNull"></el-input>
                                </div>
                                <div class="container">
                                    <el-dropdown-item
                                        v-for="(item,index) in intelligentAgentList"
                                        :key="index"
                                        class="intelligentAgentItem"
                                        :command="item"
                                    >
                                        <el-avatar shape="square" :size="30" :src="item.logoUrl"></el-avatar>
                                        <div class="name-des">
                                            <div class="name">{{ item.agentName }}</div>
                                            <div class="des">{{ item.description }}</div>
                                        </div>
                                    </el-dropdown-item>
                                    <div class="loadMore" v-if="!isLastPage" @click="loadIntelligentAgent">
                                        <span>{{ $t('common.loadMore') }}</span>
                                    </div>
                                </div>
                                <div class="addIntelligent" @click="addIntelligentFun">
                                    {{ $t('channel.addAgentBot') }}
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>

                    <!-- <template v-else-if="activeNavId === 'feedback'">
                      <feedback-setting :bindDetail="bindDetail" :channelType="channelType"></feedback-setting>
                    </template> -->
                    <template v-else-if="activeNavId === 'entranceImage' || activeNavId === 'app'">
                        <entrance-image :bindDetail="bindDetail" :activeNav="activeNavId"></entrance-image>
                    </template>
                    <template v-else-if="activeNavId === 'welcomeMsg'">
                        <div class="welcomeContainer" v-if="false">
                            <div class="welcomeHeader">
                                <div class="left">
                                    {{ $t('channel.welcomeMsg') }}
                                    <span class="check_welcome_type" @click="checkWelcomeType">
                                    {{ $t('flowComponent.changeMode') }}
                                    </span>
                                </div>
                                <div class="saveBtn" @click="save('welcome')">
                                    <i class="guoran-tongyichicun-16-03-baocun iconfont"></i>
                                    {{ $t('channel.save') }}
                                </div>
                            </div>
                            <flow-component
                                v-if="messageType === 'askbot'"
                                ref="ChatFlowComponent"
                                :isPart="0"
                                :intentIds="['','']"
                                :drawerSize="1000"
                                :isBotLink="true"
                                @saveWelcomeMessage="saveWelcomeMessage"
                            ></flow-component>
                        </div>
                    </template>
                    <template v-else-if="activeNavId === 'other'">
                      <entrance-permission ref="entrancePermission"></entrance-permission>
                    </template>
                </div>
            </div>
        </div>
        <SideNav ref="leftMenuObj"></SideNav>
        <popup @closeEvent="closeWelcomeMessage" v-if="welcomeMessageVisiable" class="welcome_message_type">
            <div slot="popup-name">{{ $t('botLink.setWelcome') }}</div>
            <div slot="popup-con" class="member-contanier">
                <p class="popup_title">{{ $t('botLink.welcomeMode') }}</p>
                <div class="welcome_content">
                    <div class="welcome_type" :class="welcomeMessageType == 'askbot' ? 'welcomeActive' : ''"
                         @click="setWelcomeType('askbot')">
                        <!-- <section class="welcome_left">
                            <i class="iconfont"></i>
                        </section> -->
                        <section class="welcome_right">
                            <p class="welcome_right_title">{{ $t('botLink.welcomeTip1') }}</p>
                            <p class="welcome_right_tip">{{ $t('botLink.welcomeTip2') }}</p>
                        </section>
                    </div>
                    <div class="welcome_type" :class="welcomeMessageType == 'knowledge' ? 'welcomeActive' : ''"
                         @click="setWelcomeType('knowledge')">
                        <!-- <section class="welcome_left">
                            <i class="iconfont"></i>
                        </section> -->
                        <section class="welcome_right">
                            <p class="welcome_right_title">{{ $t('botLink.welcomeTip3') }}</p>
                            <p class="welcome_right_tip">{{ $t('botLink.welcomeTip4') }}</p>
                        </section>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button style="width: 100px" @click="closeWelcomeMessage" plain size="small">
                    {{ $t('common.cancel') }}
                </el-button>
                <el-button style="width: 100px" type="primary" @click="sureWelcomeType" size="small">
                    {{ $t('common.confirm') }}
                </el-button>
            </div>
        </popup>
        <!-- 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用 -->
        <popup @closeEvent="dialogCheckDelBot = false" v-if="dialogCheckDelBot" class="delbot-relation-popup"
               :haveTips="true">
            <div slot="popup-name">{{ $t('botLink.dialogCheckDelBotTitle') }}</div>
            <div slot="popup-tip">{{ $t('botLink.dialogCheckDelBotTip') }}</div>
            <div slot="popup-con" class="member-contanier">
                <div v-if="relationIntents.length > 0 " class="intent-relation-content">
                    <div class="title">
                        <span class="handle-title">{{ $t('botLink.handleTitle') }}</span>
                        <span class="relation-title">{{ $t('botLink.relationTitle') }}</span>
                    </div>
                    <div
                        v-for="(item, index) in relationIntents"
                        :key="index"
                        class="relation-content">
                        <div class="handle-name">{{ item.bot.name }}</div>
                        <div class="relation-name">
                            <div
                                v-for="(relationIntentItem, rIndex) in item.relationSettings"
                                :key="rIndex"
                                @click="gotoChatFlow(item,relationIntentItem)"
                                class="relation-name-cell">
                                {{ relationIntentItem.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button style="width: 100px" @click="dialogCheckDelBot = false" plain class="cancel-btn">
                    {{ $t('common.close') }}
                </el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import DetailPageHeader from "../../components/detail-header";
import SideNav from "../menu/SideNav";
import {navList} from "./utils/navList";
import basicInfo from "./components/detailPage/basicInfo";
import BindInformation from "./components/bindInformation";
import EntranceImage from "./components/entranceImage";
import FlowComponent from "../ChatFlow/FlowComponent";
import Popup from "../../components/popupNew";
import KnowledgeWelcomeMessage from "../knowledgeWelcomeMessage";
import {submitBindBot, authoritySetConfirm} from "./utils/access";
import DingGroupTable from "./components/dingGroup/dingGroupTable";
import {requestUrl} from "../../api/requestUrl";
import EntrancePermission from "./components/entrancePermission";
// import feedbackSetting from "./components/feedbackSetting.vue"; // 智能体改版修改
export default {
    name: "accessDetails",
    components: {
      EntrancePermission,
        DingGroupTable,
        KnowledgeWelcomeMessage,
        Popup,
        FlowComponent,
        EntranceImage,
        BindInformation,
        SideNav,
        DetailPageHeader,
        basicInfo
    },
    data () {
        return {
            leftNavList: [],
            activeNavId: 0,
            previousActive: 0,
            bindDetail: {},
            welcomeMessageVisiable: false,
            welcomeMessageType: "askbot",
            welcomeMessageDate: {},
            messageType: "",
            loginModeOptions: [],
            channelType: "",
            deleteVisible: false,
            relationIntents: [],
            dialogCheckDelBot: false,
            websiteNeedLogin: false,
            originWebsiteNeedLogin: false,
            originBindCorpId: "",
            bindCorp: {
                id: "",
                name: "",
                channel: ""
            },
            allowPushMsg: false,
            wechatAccountLoginWay: "phone",
            intelligentAgentList: [],
            keyword: "",
            pageNum: 0,
            pageSize: 20,
            total: 0,
            isLastPage: true,
            isBindIntelligentAgent: false,
        }
    },
    computed: {
        headerName () {
            if (this.channelType === 'website' ||
                this.channelType === 'wechatAccount' ||
                this.channelType === 'dingding' ||
                this.channelType === 'dingdingGroup' ||
                this.channelType === 'dingSingleChatBot' ||
                this.channelType === 'feishu' ||
                this.channelType === 'yunzhijia' ||
                this.channelType === 'wechat' ||
          this.channelType === 'app'
          ) {
                return this.bindDetail.agentName;
            } else if (this.channelType === 'zhiyuan') {
                return this.$t('channel.zhiyuan.name')
            } else if (this.channelType === 'askBot') {
                return this.$t('channel.askBot')
            } else if (this.channelType === 'miniProgram') {
                return this.bindDetail.name;
            } else if (this.channelType === 'IM') {
                return this.$t('channel.IM.name');
            }
        }
    },
    watch: {
        $route (to, from) {
            //
            console.log(to, from, 'totototottotootototo');

        }
    },
    beforeRouteLeave (to, from, next) {
        if (this.$refs.bindInformation) {
            // IM 没有bindCode按渠道接入迁移之前逻辑有报错，暂不支持
            if (from.path == "/accessDetails" && this.activeNavId === 'ability' && this.channelType !== 'IM') {
                this.checkSaveAbility(next);
            } else {
                next();
            }
        } else {
            if (this.activeNavId === 'login') {
                this.checkSaveAbility(next);
            } else {
                next();
            }
        }
    },
    methods: {
        goBack () {
            sessionStorage.removeItem('previousActive');
            this.$router.push({
                path: "/channel"
            })
        },
        checkSaveAbility (next) {
            let preData, afterDate;
            if (this.activeNavId === 'ability') {
                afterDate = JSON.parse(JSON.stringify(this.$refs.bindInformation.bindBots));
                afterDate.forEach(item => {
                    item.botInfos && item.botInfos.forEach(bot => {
                        if(bot.conditions) {
                            bot.conditions = bot.conditions.filter(condition => {
                                return condition.type != ""
                            });
                        }
                    });
                });
                preData = this.$refs.bindInformation.bindBotsCopy;
                console.log(afterDate, preData, 'afterDate, preData');

            }
            if (this.activeNavId === 'login') {
                afterDate = {
                    websiteNeedLogin: this.websiteNeedLogin,
                    bindCorpId: this.bindCorp.corpId,
                };
                preData = {
                    websiteNeedLogin: this.originWebsiteNeedLogin,
                    bindCorpId: this.originBindCorpId,
                };
            }
            if (JSON.stringify(afterDate) === JSON.stringify(preData)) {
                next();
            } else {
                this.$confirm(this.$t('common.saveTheChangesTip'), this.$t('addMember.tip'), {
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    closeOnClickModal: false,
                    type: 'warning'
                }).then(() => {
                    this.save(this.activeNavId, next);
                }).catch(() => {
                    next();
                });
            }
        },
        checkNav (index, item) {
            const nextFunc = () => {
                if (item.id === 'welcomeMsg') {
                    if (!(this.channelType === 'dingding' && this.bindDetail.commonEntry == 1)) {
                        this.previousActive = this.activeNavId;
                        this.activeNavId = item.id;
                        sessionStorage.setItem('previousActive', this.previousActive)
                    } else {
                        this.showMnDdLimitTip();
                        return
                    }
                }
                this.activeNavId = item.id;
                this.messageType = "";
                this.$nextTick(() => {
                    if (item.id === 'welcomeMsg') {
                        this.setWelcomeMesaage(this.bindDetail)
                    } else if (item.id === 'login') {
                        this.getCorps4main();
                        this.websiteNeedLogin = this.bindDetail.needLogin;
                        this.bindCorp.id = this.bindDetail.corpId;
                        this.bindCorp.name = this.bindDetail.corpName;
                        this.bindCorp.channel = this.bindDetail.channel;
                        this.allowPushMsg = this.bindDetail.allowPushMsg;

                        // 原始需要登录和登录方式数据
                        this.originWebsiteNeedLogin = this.bindDetail.needLogin;
                        this.originBindCorpId = this.bindDetail.corpId;
                    }
                })
            }
            if (this.activeNavId === 'ability' || this.activeNavId === 'login') {
                this.checkSaveAbility(nextFunc);
            } else {
                nextFunc();
            }
        },
        getInfoByBindCode () {
            let bindCode = this.$route.query.bindCode;
            let url = this.requestUrl.bindV2.getInfoByBindCode + '?bindCode=' + bindCode;
            this.$http.get(url).then(res => {
                console.log('res', res)
                if (res.data.code == 0 && res.data.data) {
                    this.bindDetail = res.data.data;
                    if (this.isBindIntelligentAgent) {
                        if (!this.bindDetail.wechatBindAgent) {
                            let obj = {
                                agentBindBotInfos: [],
                                bindCode: bindCode,
                                bindId: this.bindDetail.id
                            }
                            this.$set(this.bindDetail, 'wechatBindAgent', obj)
                        }
                    }
                    sessionStorage.setItem('bindDetail', JSON.stringify(this.bindDetail))
                    this.websiteNeedLogin = this.bindDetail.needLogin;
                    this.bindCorp.id = this.bindDetail.corpId;
                    this.bindCorp.corpId = this.bindDetail.corpId;
                    this.originWebsiteNeedLogin = this.websiteNeedLogin;
                    this.originBindCorpId = this.bindCorp.corpId;

                    this.bindCorp.name = this.bindDetail.corpName;
                    this.bindCorp.channel = this.bindDetail.channel;
                }
            })
        },
        async getAskBotInfo () {
            let type = 21;
            if (this.channelType === 'IM') {
                type = 20
            }
            await this.$http.get(this.requestUrl.bindV2.channelAccessList + type).then(res => {
                if (res.data.code === "0" && res.data.data) {
                    let datas = res.data.data[0];
                    this.bindDetail = datas;
                    console.log('datas', datas)
                    if (datas.wechatBindAgent) {
                        this.$refs.bindInformation.enableBotRecognize = datas.wechatBindAgent.enableBotRecognize;
                    } else {
                        this.$refs.bindInformation.enableBotRecognize = datas.extInfo.enableBotRecognize;
                    }

                }
            });
        },
        //获取小程序渠道机器人详情
        getBotInfo () {
            let url = '/api-open/bot/' + this.$route.query.id;
            this.$http.get(url).then(res => {
                if (res.data.code == 0 && res.data.data) {
                    this.bindDetail = res.data.data;
                }
            })
        },
        // 设置/获取欢迎语
        setWelcomeMesaage (data) {
            console.log(data, 'data');
            this.welcomeMessageDate = JSON.parse(JSON.stringify(data))
            this.$http.get(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
                if (res.data.code === "0") {
                    let tampActions = res.data.data && res.data.data.setting && res.data.data.setting.unifySetStartNodeActions ? res.data.data.setting.unifySetStartNodeActions : [];
                    if (tampActions.length == 0) {
                        this.welcomeMessageVisiable = true;
                        this.welcomeMessageType = 'askbot';
                    } else {
                        if (tampActions.some(action => {
                            return action.type == 'welcome_knowledge_file' || action.type == 'welcome_llm_card'
                        })) {
                            this.messageType = 'knowledge';
                            this.$router.push({
                                path: '/knowledgeWelcomeMessage',
                                query: {
                                    bindCode: this.welcomeMessageDate.bindCode,
                                    agentName: this.welcomeMessageDate.agentName,
                                    wechatType: this.welcomeMessageDate.wechatType,
                                    type: this.channelType
                                }
                            })
                        } else {
                            this.messageType = 'askbot';
                            // this.$nextTick(() => {
                            //   this.setAskbotWelcomeMessage(this.welcomeMessageDate)
                            // })
                            this.$router.push({
                                path: '/intentWelcomeMessage',
                                query: {
                                    bindCode: data.bindCode,
                                    agentName: data.agentName,
                                    wechatType: data.wechatType,
                                    type: this.channelType
                                }
                            })
                        }
                    }
                }
            })
        },
        setAskbotWelcomeMessage (data) {
            let botIdList = [];
            console.log('data', data)
            data.bindBot && data.bindBot.botInfos.forEach(v => {
                botIdList.push(v.botId)
            })
            // this.$refs.ChatFlowComponent.getAccountGroupList(); //获取用户分组list
            this.$refs.ChatFlowComponent.getAllEntityByBot(); // 获取实体
            this.$refs.ChatFlowComponent.getWebhookBaseList(); //  获取webhookbaselist
            this.$refs.ChatFlowComponent.drawer = true;
            this.$refs.ChatFlowComponent.nodeId = "start";
            this.$refs.ChatFlowComponent.intentId = 0;
            this.$refs.ChatFlowComponent.activeNodeName = "开始";
            this.$refs.ChatFlowComponent.skillId = 0;
            this.$refs.ChatFlowComponent.isStartOrEnd = true;
            // this.$refs.ChatFlowComponent.getIntentListByBotId('',{},botIdList);
            this.$http.get(this.requestUrl.bindV2.selectUnifySetGreeting + '?bindCode=' + data.bindCode).then((res) => {
                if (res.data.code === "0") {
                    let tampActions = res.data.data && res.data.data.setting && res.data.data.setting.unifySetStartNodeActions ? res.data.data.setting.unifySetStartNodeActions : [];
                    tampActions && tampActions.forEach((action, index) => {
                        if (!("effectiveTime" in action) || action.effectiveTime == null) {
                            action.effectiveTime = {
                                range: "PERMANENT",
                                startTime: "",
                                endTime: "",
                                repeat: ["1", "2", "3", "4", "5", "6", "7",],
                                filterHolidays: true,
                                balanceHolidays: true,
                                timeRange: [
                                    new Date(),
                                    new Date(
                                        "Thu Dec 31 2100 23:59:59 GMT+0800 (中国标准时间)"
                                    ),
                                ],
                            };
                        }
                    });
                    tampActions = tampActions.filter(action => {
                        return action.type != 'welcome_knowledge_file' && action.type != 'welcome_llm_card'
                    })
                    this.$refs.ChatFlowComponent.actionList = tampActions;
                    this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify(tampActions));
                    this.$refs.ChatFlowComponent.rowDrop();
                } else {
                    this.$message.error(res.data.message);
                }
            }).catch((err) => {
                this.$message.error(this.$t('channel.welcomeMsgError'));
            });
            //  else {
            //   this.showMnDdLimitTip();
            // }
        },
        // mengniu主体下钉钉渠道所有的操作都不允许操作，点击时给提示：请联系服务商进行配置
        // 绑定机器人能进到绑定页面，但是不允许绑定机器人、修改已绑定机器人、修改可咨询范围
        showMnDdLimitTip () {
            this.$message.warning(this.$t('channel.connection'));
        },
        closeWelcomeMessage () {
            this.welcomeMessageVisiable = false;
            this.welcomeMessageType = 'askbot';
            console.log('previousActive', this.previousActive, this.activeNavId)
            this.activeNavId = this.previousActive
        },
        sureWelcomeType () {
            this.welcomeMessageVisiable = false;
            if (!this.welcomeMessageType) {
                return
            }
            this.messageType = this.welcomeMessageType;
            if (this.welcomeMessageType == 'askbot') {
                // this.$nextTick(() => {
                //   this.setAskbotWelcomeMessage(this.welcomeMessageDate)
                // })
                this.$router.push({
                    path: '/intentWelcomeMessage',
                    query: {
                        bindCode: this.bindDetail.bindCode,
                        agentName: this.bindDetail.agentName,
                        wechatType: this.bindDetail.wechatType,
                        type: this.channelType
                    }
                })
            } else {
                this.$router.push({
                    path: '/knowledgeWelcomeMessage',
                    query: {
                        bindCode: this.welcomeMessageDate.bindCode,
                        agentName: this.welcomeMessageDate.agentName,
                        wechatType: this.welcomeMessageDate.wechatType,
                        type: this.channelType
                    }
                })
            }
        },
        setWelcomeType (type) {
            this.welcomeMessageType = type;
        },
        // 获取主体下的公司
        getCorps4main () {
            this.$http.get(this.requestUrl.websiteBind.corps4main).then((res) => {
                if (res.data.code == 0) {
                    this.loginModeOptions = res.data.data || [];
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        changeLoginSwitch (datas) {
            if (this.bindDetail.bindBot && this.bindDetail.bindBot.botInfos && this.bindDetail.bindBot.botInfos.length > 1) {
                this.$message.warning(this.$t('channel.website.bindBotTip'));
                return
            }
            if (datas === 'open') {
                this.websiteNeedLogin = false;
            } else if (datas === 'close') {
                this.websiteNeedLogin = true;
            }
        },
        changePushSwitch (datas) {
            if (datas === 'open') {
                this.allowPushMsg = false;
            } else if (datas === 'close') {
                this.allowPushMsg = true;
            }
        },
        showtips () {
            this.$message.warning(
                this.$t('channel.wechatAccount.msgTip')
            );
        },
        clickFn () {

        },
        deleteAccess () {
            this.deleteVisible = false;
            this.$confirm(
                this.$t('channel.unbindTip'),
                this.$t('channel.delete'),
                {
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    type: "warning",
                }
            )
                .then(() => {
                    this.$http.post(
                        this.requestUrl.bindV2.unbindApp + '?bindId=' + this.bindDetail.id).then((res) => {
                        if (res.data.code === "0") {
                            this.$message.success(this.$t('channel.deleteSuccess'));
                            this.$router.go(-1);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                })
        },
        async save (type, callback) {
            if (type === 'login') {
                if (this.channelType === 'website') {
                    this.websiteSave();
                    if (typeof callback === 'function') {
                        callback();
                    }
                } else {
                    this.changeAllowPushMsg();
                }
            } else if (type === 'ability') {
                let bindBots = JSON.parse(JSON.stringify(this.$refs.bindInformation.bindBots));
                let bindBotsCopy = this.$refs.bindInformation.bindBotsCopy;
                let enableKnowledgeSearch = this.$refs.bindInformation.enableKnowledgeSearch;
                let dataSetCondition = this.$refs.bindInformation.$refs.identification ?
                    this.$refs.bindInformation.$refs.identification.$refs.dataSet.dataSetCondition : [];
                let pluginCondition = this.$refs.bindInformation.$refs.identification ?
                    this.$refs.bindInformation.$refs.identification.pluginCondition : [];
                if (this.channelType !== 'askBot') {
                    await this.checkStartNodeFn(bindBots, bindBotsCopy);
                    if (this.relationIntents.length > 0) {
                        this.dialogCheckDelBot = true;
                        return;
                    }
                    let result =submitBindBot(this.channelType, this.bindDetail, bindBots, enableKnowledgeSearch, this, () => {
                        if (this.channelType !== 'dingdingGroup') {
                            this.$message({
                                message: this.$t('channel.saveSuccess'),
                                type: "success"
                            })
                        }
                        this.getToBeUpdateList()
                        if (typeof callback === 'function') {
                            callback();
                        } else {
                            if (this.channelType !== 'IM') { // IM类型请求以下接口报错
                                this.$refs.bindInformation.getBotList();
                            } else {
                                this.getAskBotInfo();
                            }
                        }
                        this.getInfoByBindCode();

                    })
                    // 未正常执行保存
                    if (!result) {
                        return
                    }
                    /*这里是钉钉群有几个处于编辑态，点击保存时统一做保存*/
                    let dingGroupTable = []
                    if (this.$refs.dingGroupTable) {
                        dingGroupTable = this.$refs.dingGroupTable.dingGroupData;
                    }
                    if (dingGroupTable && dingGroupTable.length > 0) {
                        let promises = [];
                        let saveFlag = 0;
                        let saveSuccess = 0;
                        for (let i = 0; i < dingGroupTable.length; i++) {
                            let item = dingGroupTable[i];
                            let refs = 'bindInformation' + item.id;
                            if (item.edit) {
                                saveFlag += 1;
                                let groupBindBots = this.$refs.dingGroupTable.$refs[refs].bindBots;
                                let promise = new Promise((resolve) => {
                                    submitBindBot(this.channelType, item, groupBindBots, false, this, () => {
                                        saveSuccess += 1;
                                        if (typeof callback === 'function') {
                                            callback();
                                        } else {
                                            this.$refs.bindInformation.getBotList();
                                        }
                                        resolve();
                                    })
                                });
                                promises.push(promise);
                            }
                        }
                        await Promise.all(promises);
                        /*等待for循环中所有事件全部执行完*/
                        if (saveFlag == saveSuccess) {
                            this.$message({
                                message: this.$t('channel.saveSuccess'),
                                type: "success"
                            })
                            if (saveFlag > 0) {
                                this.$refs.dingGroupTable.getDingdingGroupBindList();
                            }
                        }
                    } else if (this.channelType === 'dingdingGroup') {
                        this.$message({
                            message: this.$t('channel.saveSuccess'),
                            type: "success"
                        })
                    }
                } else {
                    // this.saveAskBot();
                    let enableBotRecognize = this.$refs.bindInformation.enableBotRecognize
                    let result =submitBindBot(this.channelType, this.bindDetail, bindBots, enableBotRecognize, this, () => {
                        this.$message({
                            message: this.$t('channel.saveSuccess'),
                            type: "success"
                        })
                        this.getToBeUpdateList()
                        if (typeof callback === 'function') {
                            callback();
                        } else {
                            this.getAskBotInfo().then(() =>{
                                this.$refs.bindInformation.getBotList();
                            });
                        }
                    })
                }
                let bool = false
                if (bool && this.$refs.bindInformation.isHaveKnowledge && (this.channelType === 'website' || this.channelType === 'wechat' || this.channelType === 'dingding' || this.channelType === 'feishu' || this.channelType === 'askBot')) {
                    authoritySetConfirm(this.channelType, this.bindDetail, {
                        bindBots,
                        enableKnowledgeSearch,
                        dataSetCondition,
                        pluginCondition
                    }, this)
                }
            } else if (type === 'welcome') {
                this.saveWelcomeMessage()
            }
        },
        async getToBeUpdateList() {
            await this.$http.get(requestUrl.bindV2.getToBeUpdateList).then(res => {
                if (res.data.code == 0) {
                    this.$store.commit("CHANGE_TO_BE_UPDATE_LIST", res.data.data)
                }
            })
        },
        saveAskBot () {
            let flag = true, conditionsFlag = true;
            let bindBots = this.$refs.bindInformation.bindBots;
            if (!this.bindDetail || !this.bindDetail.id) {
                this.$message.warning(this.$t('channel.selectBindBot'))
                flag = false
                return false;
            }
            let params = {
                bindBot: {
                    botInfos: [],
                    bindId: this.bindDetail.id,
                    bindCode: this.bindDetail.bindCode,
                },
                agentBindBotInfos: [],
                "enableBotRecognize": this.$refs.bindInformation.enableBotRecognize,     // 是否开启指定机器人识别
                "enableKnowledgeSearch": this.$refs.bindInformation.enableKnowledgeSearch,  // 是否开启企业知识智能搜索
                "knowledgeSearchItems": [] // 企业知识智能搜索指定范围对应的信息，结构同items
            };

            if (bindBots.length === 0) {
                params.bindBot.botInfos = [];
            } else {
                let tampPreBindBotList = [];
                bindBots.forEach(bot => {
                    if (bot.botType !== undefined) {
                        tampPreBindBotList.push(bot);
                    }
                })
                // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
                tampPreBindBotList.forEach(item => {
                    if (!item.botId || item.botId === '') {
                        flag = false;
                    }
                    let items = [], scope = 0, conditionsCopy = [];
                    if (item.conditions && item.conditions.length > 0) {
                        item.conditions.forEach(conditionsItem => {
                            if (conditionsItem.type == 'ALL') {
                                conditionsCopy.push({
                                    expect: [],
                                    type: conditionsItem.type,
                                    key: "",
                                    relation: null,
                                })
                            } else if (conditionsItem.type == 'STAFF_OR_DEPARTMENT') {
                                conditionsCopy.push({
                                    expect: conditionsItem.expect,
                                    type: conditionsItem.type,
                                    key: "",
                                    relation: conditionsItem.relation,
                                })
                            } else if (conditionsItem.type == 'CUSTOMIZE') {
                                conditionsCopy.push({
                                    expect: conditionsItem.expect,
                                    type: conditionsItem.type,
                                    key: conditionsItem.key,
                                    relation: conditionsItem.relation,
                                })
                            }
                        })
                    } else {
                        conditionsFlag = false;
                    }

                    let obj = {
                        botId: item.botId,
                        name: item.name,
                        botType: item.botType == null ? 0 : item.botType,
                        apiKey: item.apiKey,
                        scope: scope, // 咨询范围 0：所有人 1：指定范围
                        items: items,
                        conditions: conditionsCopy,
                        plugins: item.plugins ? item.plugins.map((res) => {
                            let obj = {
                                id: res.id,
                                name: res.name,
                                scope: res.scope,
                                conditions: res.conditions.map(ress => {
                                    let conditions = {
                                        expect: [],
                                        type: ress.type,
                                        key: "",
                                        relation: null,
                                    }
                                    if (ress.type == 'ALL') {
                                        conditions.type = ress.type
                                    } else if (ress.type == 'STAFF_OR_DEPARTMENT') {
                                        conditions.expect = ress.expect
                                        conditions.type = ress.type
                                        conditions.relation = ress.relation
                                    } else if (ress.type == 'CUSTOMIZE') {
                                        conditions.expect = ress.expect
                                        conditions.type = ress.type
                                        conditions.key = ress.key;
                                        conditions.relation = ress.relation
                                    }
                                    return conditions
                                })
                            }
                            return obj
                        }) : []
                    }
                    params.bindBot.botInfos.push(obj)

                })
            }
            if (!flag) {
                this.$message.warning(this.$t('channel.selectBindBot'));
                return false;
            }
            if (!conditionsFlag) {
                this.$message.warning(this.$t('channel.conditionsTip'));
                return false;
            }
            this.$http.post(this.requestUrl.bindV2.clientBindBot, params).then(res => {
                if (res.data.code == '0' && res.data.data) {
                    this.$message.success(this.$t('channel.saveSuccess'))
                }
            })
        },
        websiteSave () {
            let objData = {
                domain: this.bindDetail.domain,
                websiteName: this.bindDetail.websiteName,
                channel: this.bindCorp.channel,
                corpId: this.bindCorp.corpId,
                corpName: this.bindCorp.name,
                needLogin: this.websiteNeedLogin
            };
            this.$http.put(
                this.requestUrl.websiteBind.updateWebInfo + '/' + this.bindDetail.id,
                objData
            ).then((res) => {
                if (res.data.code === "0") {
                    this.$message.success(this.$t('channel.saveSuccess'));
                    this.getInfoByBindCode();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        changeAllowPushMsg () {
            this.$http.post(
                this.requestUrl.wechatBind.changeAllowPushMsg +
                this.bindDetail.id +
                "?status=" +
                this.allowPushMsg
            ).then((res) => {
                if (res.data.code === "0") {
                    this.$message.success(this.$t('channel.saveSuccess'))
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 渠道管理删除机器人，校验机器人下所有意图是否被统一欢迎语配置使用
        checkStartNodeFn (bindBots, bindBotsCopy) {
            this.relationIntents = []
            let botIds = [];
            bindBots.forEach(newItem => {
                botIds.push(newItem.botId)
            })
            let apiKeys = [];
            bindBotsCopy.forEach(oldItem => {
                if ((botIds.indexOf(oldItem.botId) === -1 || botIds.length === 0) && oldItem.apiKey) {
                    apiKeys.push(oldItem.apiKey)
                }
            });
            if (apiKeys.length > 0) {
                return this.$http.post(this.requestUrl.bindV2.checkStartNode + '?bindCode=' + this.bindDetail.bindCode, apiKeys).then((res) => {
                    if (res.data.code === "0") {
                        this.relationIntents = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            }
        },
        changeCompany (val) {
            let data = this.loginModeOptions.find(item => item.corpId === val);
            this.bindCorp.name = data.corpName;
            this.bindCorp.channel = data.corpType;
            this.bindCorp.corpId = val;
            this.bindCorp.id = data.corpId;
        },
        checkWelcomeType () {
            this.$router.push({
                path: '/knowledgeWelcomeMessage',
                query: {
                    bindCode: this.bindDetail.bindCode,
                    agentName: this.bindDetail.agentName,
                    wechatType: this.bindDetail.wechatType,
                    type: this.channelType
                }
            })
        },
        // 保存欢迎语
        saveWelcomeMessage () {
            let data = this.$refs.ChatFlowComponent.actionList;
            this.$http.post(this.requestUrl.bindV2.unifySetGreeting, {
                bindCode: this.bindDetail.bindCode,
                unifySetStartNodeActions: data
            }).then((res) => {
                if (res.data.code === "0") {
                    this.$message.success(this.$t('channel.saveSuccess'));
                    this.$refs.ChatFlowComponent.actionListCopy = JSON.parse(JSON.stringify(this.$refs.ChatFlowComponent.actionList));
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 跳转到欢迎语
        gotoChatFlow (item) {
            let url = process.env.VUE_APP_OPEN_HOST + '/#/main/link?openDrawerType=' + item.wechatType + '&bindCode=' + this.bindDetail.bindCode;
            window.open(url, "_blank");
        },
        asynToAllGroup () {
            let bindId = this.bindDetail.id;
            this.$http.post(
                this.requestUrl.dingdingGroup.sync2group + "?bindId=" + bindId,
                {
                    bindId: bindId,
                }
            ).then((res) => {
                if (res.data.code === "0") {
                    this.$message.success(this.$t('botLink.dingGroupListDrawer.syncSuccess'));
                    if (this.$refs.dingGroupTable) {
                        this.$refs.dingGroupTable.currentPage = 1;
                        this.$refs.dingGroupTable.getDingdingGroupBindList();
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        gotoIntelligentAgentConfig (item) {
            this.$refs.bindInformation.setAgentBind(item)
            // this.$router.push({
            //   path:"/intelligentAgentConfig",
            //   query:{
            //     id:id
            //   }
            // })
        },
        search (event) {
            event.preventDefault();
            if (!this.keyword) {
                return
            }
            this.pageNum = 0;
            this.intelligentAgentList = [];
            this.getIntelligentAgentList();
        },
        searchNull () {
            if (!this.keyword) {
                this.pageNum = 0;
                this.intelligentAgentList = [];
                this.getIntelligentAgentList();
            }
        },
        addIntelligentFun () {
            sessionStorage.setItem('addIntelligentAgentDialog', true);
            this.$router.push({
                path: "/channel"
            })
        },
        getIntelligentAgentList () {
            let url = requestUrl.intelligentAgent.getList;
            url += '?keyWord=' + this.keyword + '&pageNum=' + this.pageNum + '&pageSize=' + this.pageSize;
            this.$http.get(url).then(res => {
                if (res.data.code == 0 && res.data.data) {
                    this.intelligentAgentList = this.intelligentAgentList.concat(res.data.data.list);
                    this.total = res.data.data.total;
                    this.isLastPage = res.data.data.isLastPage;
                }
            })
        },
        loadIntelligentAgent () {
            this.pageNum += 1;
            this.getIntelligentAgentList();
        }
    },
    mounted () {
        this.channelType = this.$route.query.type;
        if (this.$route.query.type === 'website') {
            this.leftNavList = navList.website;
        } else if (this.$route.query.type === 'wechatAccount') {
            this.leftNavList = navList.wechatAccount;
        } else if (this.$route.query.type === 'dingding' || this.$route.query.type === 'feishu' || this.$route.query.type === 'yunzhijia' || this.$route.query.type === 'wechat') {
            this.leftNavList = navList.common_1
        } else if (this.$route.query.type === 'dingdingGroup' || this.$route.query.type === 'dingSingleChatBot') {
            this.leftNavList = navList.common_2
        } else if (this.$route.query.type === 'zhiyuan' || this.$route.query.type === 'IM' || this.$route.query.type === 'askBot') {
            this.leftNavList = navList.ability;
        } else if (this.$route.query.type === 'miniProgram') {
            this.leftNavList = navList.miniProgram
        } else if(this.$route.query.type === 'app') {
        this.leftNavList = navList.common_1
    }
        if (sessionStorage.getItem('previousActive')) {
            this.activeNavId = sessionStorage.getItem('previousActive')
            sessionStorage.setItem('previousActive', this.activeNavId);
        } else {
            this.activeNavId = this.leftNavList[0].id;
        }
        this.previousActive = this.activeNavId;
        if (this.$route.query.type !== 'askBot' && this.$route.query.type !== 'miniProgram' && this.$route.query.type !== 'IM') {
            this.getInfoByBindCode();
        } else if (this.$route.query.type === 'askBot' || this.$route.query.type === 'IM') {
            this.getAskBotInfo()
        } else if (this.$route.query.type === 'miniProgram') {
            this.getBotInfo()
        }
        this.isBindIntelligentAgent = this.$route.query.type === 'feishu' || this.$route.query.type === 'wechat' || this.$route.query.type === 'dingding' || this.$route.query.type === 'askBot'
        if (this.isBindIntelligentAgent) {
            this.getIntelligentAgentList();
        }
        if(this.$route.query.nav) {
            this.activeNavId = this.$route.query.nav;
        }
    }
};
</script>

<style scoped lang="less">
.accessDetails {
    .gr-content-container {
        position: absolute;
        left: 64px;
        width: calc(100% - 96px);
        height: calc(100% - 24px);
        padding: 12px 16px;
        min-width: 1000px;
        overflow-x: auto;
        text-align: left;

        .detailContainer {
            display: flex;
            align-items: center;
            margin-top: 20px;
            height: calc(100% - 82px);

            .leftNav {
                flex: none;
                width: 230px;
                height: 100%;
                background-color: #FFFFFF;

                .navItem {
                    margin: 10px 0;
                    padding: 14px 20px;
                    border-left: 2px solid #FFFFFF;
                    cursor: pointer;

                    i {
                        font-size: 14px;
                        margin-right: 4px;
                    }
                }

                .activeNav {
                    border-left: 2px solid #366aff !important;
                    background: #f1f4ff;
                    color: #366aff;
                }
            }

            .rightContent {
                flex: 1;
                height: 100%;
                margin-left: 16px;
                //background-color: #FFFFFF;
                box-sizing: border-box;
                //padding: 20px;
                width: calc(100% - 240px);

                .abilityManage {
                    height: 100%;
                    //padding-right: 20px;
                    overflow-y: scroll;
                    background-color: #FFFFFF;
                    padding: 20px;
                    border-radius: 5px;
                    box-sizing: border-box;

                    .bindInformation {
                        max-width: 1200px;
                        min-width: 1000px;
                    }

                    .header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        .left {
                            font-size: 16px;
                            color: #000000;
                            display: flex;
                            align-items: center;}
                        }


                    .addBindIntelligent {
                        display: flex;
                        align-items: center;
                        color: #366aff;
                        cursor: pointer;
                        margin-top: 30px;

                        .addIcon {
                            flex: none;
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #366aff;
                            margin-right: 10px;
                            border-radius: 5px;

                            i {
                                color: #FFFFFF;}
                            }
                        }
                    }

                }

                .welcomeHeader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 14px;

                    .left {
                        font-size: 16px;
                        color: #000000;
                        display: flex;
                        align-items: center;

                        .check_welcome_type {
                            color: #366aff;
                            padding-left: 10px;
                            font-size: 14px;
                            cursor: pointer;
                        }
                    }

                    .right {
                        height: 26px;
                        background-color: #366aff;
                        color: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 14px;
                        font-size: 12px;
                        border-radius: 17px;
                    }
                }

                .needLogin {
                    background-color: #FFFFFF;
                    padding: 20px;
                    box-sizing: border-box;
                    height: 100%;
                    border-radius: 5px;

                    .header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        .left {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #000000;

                            i {
                                color: #366aff;
                                margin-right: 6px;
                            }
                        }
                    }

                    .login {
                        display: flex;
                        align-items: center;
                        background-color: #F6F8FD;
                        padding: 10px;
                        border-radius: 5px;
                        position: relative;
                        margin-bottom: 20px;

                        .tips {
                            font-size: 14px;
                            color: #999999;
                            margin-left: 14px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 18px;
                                margin-right: 4px;
                            }
                        }

                        .switch-open-icon {
                            position: absolute;
                            z-index: 999;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 12px;

                            i {
                                color: white;
                                font-size: 16px;
                            }
                        }

                        .switch-close-icon {
                            position: absolute;
                            z-index: 999;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 32px;

                            i {
                                color: #6E6B7B;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }

                    .loginWay {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        .tips {
                            margin-left: 14px;
                            color: #999999;
                            font-size: 14px;

                            i {
                                color: #FF9555;
                                margin-right: 4px;
                            }
                        }
                    }

                    .company {
                        display: flex;
                        align-items: center;
                        background-color: #F6F8FD;
                        padding: 10px;
                        border-radius: 5px;
                        //height: 40px;
                    }
                }

                .dingGroupTip {
                    margin: 0 20px;
                    color: #999999;
                    font-size: 14px;
                }

                .synchronous {
                    height: 26px;
                    background-color: #366aff;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 14px;
                    font-size: 12px;
                    border-radius: 17px;
                    cursor: pointer;
                }

                .saveBtn {
                    height: 26px;
                    background-color: #366aff;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 14px;
                    font-size: 12px;
                    border-radius: 17px;
                    cursor: pointer;

                    i {
                        font-size: 12px;
                        padding-right: 4px;
                    }
                }

                .welcomeContainer {
                    height: 100%;
                    background-color: #FFFFFF;
                    padding: 20px;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
        }

    .header-c-r-btn {
        margin-right: 20px;
    }
}


.welcome_message_type {
    #popup-assembly-new {
        width: 500px !important;
    }

    .popup_title {
        line-height: 28px;
        text-align: left;
        font-size: 16px;
    }

    .welcome_content {
        width: 100%;

        .welcome_type {
            width: 100%;
            height: 70px;
            border-radius: 15px;
            background: #f0f4ff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 12px 0;
            text-align: left;

            .welcome_left {
                width: 48px;
                height: 48px;
                border-radius: 10px;
                overflow: hidden;
                background: #606266;
                margin: 0 16px;
            }

            .welcome_right {
                // width: calc(100% - 80px);
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 16px;
                box-sizing: border-box;

                .welcome_right_title {
                    margin: 2px 0;
                }

                .welcome_right_tip {
                    margin: 2px 0;
                    color: #A9B3C6;
                }
            }
        }

        .welcomeActive {
            border: 1px solid #366AFF;
        }
    }
}

.delbot-relation-popup {
    #popup-assembly-new {
        width: 790px !important;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        line-height: 34px;
        height: 34px;
        padding: 6px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        color: #616161;
        line-height: 44px;

        .handle-title {
            width: 240px;
            flex: none;
            text-align: left;
        }

        .relation-title {
            width: 240px;
            flex: auto;
            text-align: left;
        }
    }

    .relation-content {
        display: flex;
        justify-content: flex-start;
        line-height: 24px;
        padding: 6px;

        .handle-name {
            flex: none;
            text-align: left;
            width: 200px;
            line-height: 20px;
            // height: 112px;
            max-height: 112px;
            background: #F6F8FD;
            border-radius: 5px;
            padding: 12px;
            font-size: 14px;
            color: #000000;
        }

        .relation-name {
            flex: auto;
            text-align: left;
            margin-left: 12px;
            width: 200px;
            // height: 112px;
            //  max-height: 112px;
            //  overflow: auto;
            background: #F6F8FD;
            border-radius: 5px;
            padding: 12px;
            padding-bottom: 0;
            font-size: 14px;
            color: #000000;

            .relation-name-cell {
                color: #366AFF;
                cursor: pointer;
                white-space: nowrap;
                margin-bottom: 10px;
                cursor: pointer;
                height: 20px;
                line-height: 20px;
            }
        }
    }
}

.delete-btn {
    min-width: 110px !important;
    padding: 8px 0 !important;

    .popoverItem {
        display: flex;
        align-items: center;
        height: 36px;
        cursor: pointer;
        padding: 0 12px;

        i {
            margin-right: 10px;
            font-size: 14px;
            color: #366aff;
        }
    }

    .popoverItem:hover {
        background: #F5F8FF;
    }
}

.search {
    /deep/ .el-input__inner {
        border: none;
        border-bottom: 1px solid #E4E9F2;
    }
}

.el-dropdown-menu {
    overflow-y: hidden;
    padding: 0;
}

.container {
    max-height: 300px;
    overflow-y: scroll;

    .loadMore {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #366aff;
        cursor: pointer;
    }

    .intelligentAgentItem {
        display: flex;
        align-items: center;
        line-height: 20px;
        padding: 10px;
        border-bottom: 1px solid #E4E9F2;
        width: 150px;
        cursor: pointer;

        .name-des {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .name {
                margin-bottom: 4px;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }

            .des {
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
                color: #A9B3C6;
            }
        }
    }
}

.addIntelligent {
    height: 36px;
    min-width: 160px;
    //width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #366aff;
    cursor: pointer;
}
</style>
